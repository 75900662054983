import { toast } from "react-toastify";
import api from "../../../services/api";
import { ModalContainer, PedidoComprasMaterialContainer } from "./styles";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import FecharSvg from "../../../assets/fechar.svg";
import Relatorio from "../../../assets/relatorio.svg";
import GerarPedidoComprasModal from "../../Modal/GerarPedidoComprasModal";
import Loading from "../../Loading";
import ModalAcompanharMaterialDistribuicoes from "../ModalAcompanharMaterialDistribuicoes";
import GerarRelatorioDisputaModal from "../../Modal/GerarRelatorioDisputa";
import { useAuth } from "../../../providers/Auth";

const PedidoComprasMaterial = ({
  campanha,
  material,
  buscarPropostaMateriais,
  buscarMaterialBriefing,
  marcas,
}) => {
  const { user } = useAuth();
  const [provaHistorico, setProvaHistorico] = useState([]);
  const [materialDistribuicoes, setMaterialDistribuicoes] = useState([]);
  const [gerarRelatorio, setGerarRelatorio] = useState(false);

  const [dataAtual] = useState(() => {
    const hoje = new Date();
    const ano = hoje.getFullYear();
    const mes = String(hoje.getMonth() + 1).padStart(2, "0");
    const dia = String(hoje.getDate()).padStart(2, "0");
    return `${ano}-${mes}-${dia}`;
  });
  const [provaComentarios, setProvaComentarios] = useState("");
  const [dataProva, setDataProva] = useState("");

  const [materialPedidoCompras, setMaterialPedidoCompras] = useState(false);
  const [enviarPedidoFornecedor, setEnviarPedidoFornecedor] = useState(false);
  const [reprovarProva, setReprovarProva] = useState(false);
  const [liberarProducao, setLiberarProducao] = useState(false);
  const [finalizarProducao, setFinalizarProducao] = useState(false);
  const [acompanharDistribuicoes, setAcompanharDistribuicoes] = useState(false);
  const [concluir, setConcluir] = useState(false);

  const [provaHistoricoLoaded, setProvaHistoricoLoaded] = useState(false);
  const [materialDistribuicoesLoaded, setMaterialDistribuicoesLoaded] =
    useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (provaHistoricoLoaded && materialDistribuicoesLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [provaHistoricoLoaded, materialDistribuicoesLoaded]);

  const buscarProvaHistorico = () => {
    setProvaHistoricoLoaded(false);
    const status =
      material.status === "reprovado" ? "reprovado" : "em producao";
    api
      .get(`/proposta-materiais/${material.id}/historico?status=${status}`)
      .then((response) => {
        setProvaHistorico(response.data.data.historico);
        setProvaHistoricoLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar histórico de provas.");
      });
  };

  const buscarMaterialDistribuicoes = () => {
    setMaterialDistribuicoesLoaded(false);
    api
      .get(`/proposta-material-distribuicao/${material.id}`)
      .then((response) => {
        setMaterialDistribuicoes(response.data.data.distribuicoes);
        setMaterialDistribuicoesLoaded(true);
        const distribuicoes = response.data.data.distribuicoes;

        const filtro = distribuicoes.filter(
          (elem) => elem.nf_fornecedor !== null && elem.nf_fornecedor !== ""
        );

        if (
          distribuicoes.length > 0 &&
          filtro.length === distribuicoes.length
        ) {
          setConcluir(true);
        }
      })
      .catch((error) => {
        toast.error("Erro ao buscar distribuições.");
      });
  };

  useEffect(() => {
    buscarProvaHistorico();
    buscarMaterialDistribuicoes();
  }, []);

  const adicionaArquivoImpressao = (arquivo) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("arquivo", arquivo);

    api
      .post(`/proposta-materiais/${material.id}/arquivo`, formData)
      .then((response) => {
        toast.success("Arquivo impressão adicionado com sucesso.");
        buscarPropostaMateriais();
      })
      .catch((error) => {
        toast.error("Erro ao adicionar arquivo impressão.");
      });
  };

  const handleEnviaPedidoFornecedor = () => {
    const dados = {
      status: "aguardando prova",
      data_prova: dataProva.split("-").reverse().join("/"),
    };
    if (!material.arquivo_impressao) {
      return toast.error("Adicione um arquivo de Impressão");
    }

    api
      .patch(`/proposta-materiais/${material.id}`, dados)
      .then((response) => {
        toast.success("Pedido enviado para fornecedor com sucesso.");
        buscarPropostaMateriais();
      })
      .catch((error) => {
        toast.error("Erro ao enviar pedido para fornecedor.");
      });

    api
      .post(`/adicionar-anexo`, { dados: material })
      .then((response) => {})
      .catch((error) => {
        toast.error("Erro ao enviar pedido para fornecedor.");
      });
  };

  const handleReprovaProva = () => {
    const dados = {
      status: "reprovado",
      comentarios: provaComentarios,
    };
    setLoading(true);
    api
      .patch(`/proposta-materiais/${material.id}`, dados)
      .then((response) => {
        toast.success("Prova reprovada com sucesso.");
        buscarPropostaMateriais();
      })
      .catch((error) => {
        toast.error("Erro ao reprovar prova.");
      });
  };

  const handleLiberaProducao = () => {
    setLoading(true);

    api
      .patch(`/proposta-materiais/${material.id}`, { status: "em producao" })
      .then((response) => {
        toast.success("Produção liberada com sucesso.");
        buscarPropostaMateriais();
      })
      .catch((error) => {
        toast.error("Erro ao liberar produção.");
      });

    if (material.orcamento_id) {
      api
        .get(
          `/orcamento-materiais-briefing/orcamento/${Number(
            material.orcamento_id
          )}`
        )
        .then((response) => {
          api
            .patch(
              `/campanha-material-briefing/${response.data.data.orcamentos[0].campanha_material_briefing_id}`,
              {
                status: "em produção",
              }
            )
            .then((response) => {
              buscarMaterialBriefing();
            })
            .catch((error) => {
              toast.error("Erro ao liberar produção.");
            });

          //TODO AJUSTAR
          api
            .patch(
              `/orcamento-materiais-briefing/${Number(material.orcamento_id)}`,
              {
                status: "em produção",
              }
            )
            .then((response) => {
              buscarMaterialBriefing();
            })
            .catch((error) => {
              toast.error("Erro ao liberar produção.");
            });
        })
        .catch((error) => {
          toast.error("Erro ao liberar produção.");
        });
    } else {
      api
        .get(
          `/campanha-material-vitrine//campanha_material_id/${Number(
            material.material_vitrine_id
          )}`
        )
        .then((response) => {
          api
            .patch(
              `/campanha-material-vitrine/${response.data.data.materiais[0].id}`,
              {
                status: "em produção",
              }
            )
            .then((response) => {
              buscarMaterialBriefing();
            })
            .catch((error) => {
              toast.error("Erro ao liberar produção.");
            });
        })
        .catch((error) => {
          toast.error("Erro ao liberar produção.");
        });
    }
  };

  const handleFinalizaProducao = () => {
    setLoading(true);

    api
      .patch(`/proposta-materiais/${material.id}`, { status: "concluido" })
      .then((response) => {
        toast.success("Produção finalizada com sucesso.");
        buscarPropostaMateriais();
      })
      .catch((error) => {
        toast.error("Erro ao finalizar produção.");
      });

    const orcamentoId = Number(material.orcamento_id);

    if (material.orcamento_id) {
      api
        .get(`/orcamento-materiais-briefing/orcamento/${orcamentoId}`)
        .then((response) => {
          api
            .patch(
              `/campanha-material-briefing/${response.data.data.orcamentos[0].campanha_material_briefing_id}`,
              { status: "concluido" }
            )
            .then((response) => {
              api
                .patch(`/orcamento-materiais-briefing/${orcamentoId}`, {
                  status: "concluido",
                })
                .then((response) => {})
                .catch((error) => {
                  toast.error("Erro ao finalizar produção.");
                });
              buscarPropostaMateriais();
              buscarMaterialBriefing();
            })
            .catch((error) => {
              toast.error("Erro ao finalizar produção.");
            });
        })
        .catch((error) => {
          toast.error("Erro ao finalizar produção.");
        });
    } else {
      api
        .patch(
          `/campanha-material-vitrine/${Number(material.material_vitrine_id)}`,
          { status: "concluido" }
        )
        .then((response) => {
          buscarPropostaMateriais();
          buscarMaterialBriefing();
        })
        .catch((error) => {
          toast.error("Erro ao finalizar produção.");
        });
    }
  };

  // function relatorioDisputa() {
  //   console.log(material.campanha_material_briefing_id);
  //   api
  //     .get(
  //       `/relatorios/disputa/${material.campanha_material_briefing_id}/${material.quantidade}`
  //     )
  //     .then((response) => {
  //       console.log(response.data.data);
  //       console.log("sucesso");
  //     })
  //     .catch(() => {
  //       console.log("erro");
  //     });
  // }

  return (
    <PedidoComprasMaterialContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="header">
            <b>Item</b>
            <b>Status</b>
          </div>
          <div className="body">
            <div className="content-1">
              <p>
                {material.nome} {material.subnome ? material.subnome : ""}
              </p>
              <div className="status">
                {material.status === "pendente" && (
                  <div className="pendente">aguardando liberação</div>
                )}
                {material.status === "aguardando prova" && (
                  <div className="prova">aguardando prova</div>
                )}
                {material.status === "em producao" && (
                  <div className="producao">em produção</div>
                )}
                {material.status === "concluido" && (
                  <div className="concluido">concluído</div>
                )}
              </div>
            </div>
            <div className="content-2">
              <div className="quantidade">
                <b>Quantidade</b>
                <p>{material.quantidade} un.</p>
              </div>
              <div className="fornecedor">
                <b>Fornecedor</b>
                <p>{material.fornecedor_nome}</p>
              </div>
              <div className="entrega">
                <b>Entrega</b>
                {material.prazo_entrega}{" "}
                {material.prazo_entrega && material.prazo_entrega === 1
                  ? "dia útil"
                  : "dias úteis"}
              </div>
              <div className="arquivo">
                <b>Arquivo impressão</b>
                {material.arquivo_impressao ||
                material.status !== "pendente" ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={material.arquivo_impressao}
                  >
                    Arquivo Impressão #{material.id}.png
                  </a>
                ) : (
                  <div className="input-container">
                    <input
                      id={`arquivo${material.id}`}
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                      onChange={(e) => {
                        adicionaArquivoImpressao(e.target.files[0]);
                      }}
                    />
                    <label htmlFor={`arquivo${material.id}`}>
                      Selecionar arquivo
                    </label>
                    <label
                      htmlFor={`arquivo${material.id}`}
                      className="pesquisar"
                    >
                      pesquisar
                    </label>
                  </div>
                )}
              </div>
              {user.perfil == "administrador" && (
                <div className="pedido-compras">
                  <b>Pedido de Faturamento</b>
                  <button
                    className="pdf"
                    onClick={() => setMaterialPedidoCompras(true)}
                  >
                    {material.nome} #{material.id}.pdf
                  </button>
                  {material.status === "pendente" && (
                    <button
                      className="fornecedor"
                      onClick={() => setEnviarPedidoFornecedor(true)}
                    >
                      Enviar para o fornecedor {">"}
                    </button>
                  )}
                </div>
              )}
            </div>
            {material.status === "aguardando prova" && (
              <div className="prova-container">
                <p>
                  <b>Prova</b> Envio até {material.data_prova}
                </p>
                <div className="historico">
                  {provaHistorico.map((prova) => (
                    <div className="prova-historico" key={prova.id}>
                      {prova.data_criacao} - Prova reprovada por{" "}
                      {prova.criador_nome} {prova.criador_sobrenome}.
                      {prova.comentarios && `Comentários: ${prova.comentarios}`}
                    </div>
                  ))}
                </div>
                <div className="status">
                  <input
                    className="comentarios"
                    placeholder="Comentários"
                    value={provaComentarios}
                    onChange={(e) => setProvaComentarios(e.target.value)}
                  />
                  <button
                    className="reprovar"
                    onClick={() => setReprovarProva(true)}
                  >
                    Reprovada - Pedir revisão
                  </button>
                  <button
                    className="aprovar"
                    onClick={() => setLiberarProducao(true)}
                  >
                    Aprovada - liberar produção
                  </button>
                </div>
              </div>
            )}
            {["em producao", "concluido"].includes(material.status) && (
              <div className="producao-container">
                <div className="historico">
                  {provaHistorico.map((prova) => (
                    <div className="prova-historico" key={prova.id}>
                      {prova.data_criacao} - Prova Aprovada e liberada pra
                      produção por {prova.criador_nome}{" "}
                      {prova.criador_sobrenome}.
                      {prova.comentarios && `Comentários: ${prova.comentarios}`}
                      <a
                        onClick={() => {
                          setGerarRelatorio(!gerarRelatorio);
                        }}
                      >
                        {user.perfil == "administrador" && (
                          <ReactSVG
                            className="icone-relatorio"
                            src={Relatorio}
                          />
                        )}
                        <h4>Relatório</h4>
                      </a>
                    </div>
                  ))}
                </div>
                <div className="producao">
                  <div className="distribuicao">
                    <b>Distribuição / Entrega</b>{" "}
                    <button onClick={() => setAcompanharDistribuicoes(true)}>
                      acompanhar {">"}
                    </button>
                  </div>
                  {material.data_producao ? (
                    <p>
                      <b>Produção:</b> Produção concluída em{" "}
                      {material.data_producao}
                    </p>
                  ) : (
                    <button
                      className="finalizar"
                      onClick={() => setFinalizarProducao(true)}
                      disabled={concluir ? false : true}
                    >
                      Finalizar produção {">"}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {materialPedidoCompras && (
        <GerarPedidoComprasModal
          setModalOpened={setMaterialPedidoCompras}
          campanha={campanha}
          material={material}
        />
      )}
      {gerarRelatorio && (
        <GerarRelatorioDisputaModal
          setModalOpened={setGerarRelatorio}
          material={material}
          campanha={campanha}
          marcas={marcas}
        />
      )}
      {enviarPedidoFornecedor && (
        <ModalContainer onClick={() => setEnviarPedidoFornecedor(false)}>
          <div className="content" onClick={(e) => e.stopPropagation()}>
            <button
              className="fechar"
              onClick={() => setEnviarPedidoFornecedor(false)}
            >
              <ReactSVG src={FecharSvg} />
            </button>
            <h1>Enviar pedido para o fornecedor</h1>
            {enviarPedidoFornecedor.arquivo_impressao && (
              <p>Nenhum arquivo de impressão foi adicionado.</p>
            )}
            <div className="input-container">
              Entrega da prova:
              <input
                type="date"
                min={dataAtual}
                value={dataProva}
                onChange={(e) => setDataProva(e.target.value)}
              />
            </div>
            <button
              className="enviar"
              disabled={!dataProva}
              onClick={handleEnviaPedidoFornecedor}
            >
              Enviar {">"}
            </button>
          </div>
        </ModalContainer>
      )}
      {reprovarProva && (
        <ModalContainer onClick={() => setReprovarProva(false)}>
          <div className="content" onClick={(e) => e.stopPropagation()}>
            <button className="fechar" onClick={() => setReprovarProva(false)}>
              <ReactSVG src={FecharSvg} />
            </button>
            <p>Tem certeza que deseja mandar a prova para revisão?</p>
            <div className="buttons-container">
              <button onClick={() => setReprovarProva(false)}>NÃO</button>
              <button onClick={handleReprovaProva}>SIM</button>
            </div>
          </div>
        </ModalContainer>
      )}
      {liberarProducao && (
        <ModalContainer onClick={() => setLiberarProducao(false)}>
          <div className="content" onClick={(e) => e.stopPropagation()}>
            <button
              className="fechar"
              onClick={() => setLiberarProducao(false)}
            >
              <ReactSVG src={FecharSvg} />
            </button>
            <p>Tem certeza que deseja liberar para produção?</p>
            <div className="buttons-container">
              <button onClick={() => setLiberarProducao(false)}>NÃO</button>
              <button onClick={handleLiberaProducao}>SIM</button>
            </div>
          </div>
        </ModalContainer>
      )}
      {finalizarProducao && (
        <ModalContainer onClick={() => setFinalizarProducao(false)}>
          <div className="content" onClick={(e) => e.stopPropagation()}>
            <button
              className="fechar"
              onClick={() => setFinalizarProducao(false)}
            >
              <ReactSVG src={FecharSvg} />
            </button>
            <p>Tem certeza que deseja finalizar a produção?</p>
            <div className="buttons-container">
              <button onClick={() => setFinalizarProducao(false)}>NÃO</button>
              <button onClick={handleFinalizaProducao}>SIM</button>
            </div>
          </div>
        </ModalContainer>
      )}
      {acompanharDistribuicoes && (
        <ModalAcompanharMaterialDistribuicoes
          setModalOpened={setAcompanharDistribuicoes}
          material={material}
          materialDistribuicoes={materialDistribuicoes}
          buscarMaterialDistribuicoes={buscarMaterialDistribuicoes}
          setConcluir={setConcluir}
        />
      )}
    </PedidoComprasMaterialContainer>
  );
};

export default PedidoComprasMaterial;
