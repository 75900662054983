import { useNavigate } from "react-router-dom";
import { TableContainer } from "./styles";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../../providers/Auth";
import Loading from "../../../../components/Loading";
import CardCampanhaBriefing from "../../../../components/CardCampanha";

const Table = ({
  status,
  clienteSelecionado,
  atendimentoSelecionado,
  produtorSelecionado,
  textoPesquisa,
}) => {
  const [campanhas, setCampanhas] = useState([]);
  const [briefings, setBriefings] = useState([]);
  const [campanhasComBriefings, setCampanhasComBriefings] = useState([]);
  const [vitrine, setVitrine] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, token } = useAuth();

  function buscarCliente() {
    if (
      user.permissoes.includes("admin") ||
      user.permissoes.includes("compras")
    ) {
      return buscarCampanhas();
    }
    api
      .get(`/usuario-cliente/${user.id}`)
      .then((response) => {
        buscarCampanhas(response.data.data.usuario.cliente_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhas() {
    api
      .get("/campanhas")
      .then((response) => {
        const data = response.data.data.campanhas;
        setCampanhas(data);
        setLoading(true);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhasComBriefings() {
    api
      .get("/campanhas/briefings", {
        params: {
          produtor: user.perfil == "produtor" ? user.id : produtorSelecionado,
          atendimento: atendimentoSelecionado,
          status: status,
        },
      })
      .then((response) => {
        const data = response.data.data.campanhas;
        setCampanhasComBriefings(data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarBriefingCampanha() {
    api
      .get(
        `/campanha-material-briefing?${status ? `status=${status}` : ""}${
          produtorSelecionado ? `&produtor=${produtorSelecionado}` : ""
        }${
          atendimentoSelecionado ? `&atendimento=${atendimentoSelecionado}` : ""
        }${clienteSelecionado ? `&cliente=${clienteSelecionado}` : ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setBriefings(response.data.data.materiais);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarVitrineCampanha() {
    api
      .get(`/campanha-material-vitrine?${status ? `status=${status}` : ""}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVitrine(response.data.data.materiais);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    buscarBriefingCampanha();
    buscarCliente();
    buscarVitrineCampanha();
    buscarCampanhasComBriefings();
  }, [user]);

  useEffect(() => {
    buscarBriefingCampanha();
    buscarVitrineCampanha();
    buscarCampanhasComBriefings();
  }, [status, produtorSelecionado, atendimentoSelecionado, clienteSelecionado]);

  const filtrarCampanha = (campanha) => {
    const campanhaFiltrada =
      !clienteSelecionado || campanha.cliente_id == clienteSelecionado;
    const pesquisaFiltrada =
      !textoPesquisa ||
      campanha.campanha_nome
        .toLowerCase()
        .includes(textoPesquisa.toLowerCase());

    const idCampanha = parseInt(textoPesquisa);
    const idFiltrado = !isNaN(idCampanha) && campanha.id === idCampanha;

    return campanhaFiltrada && (pesquisaFiltrada || idFiltrado);
  };

  const campanhasFiltradas = campanhasComBriefings.filter(filtrarCampanha);

  return (
    <TableContainer>
      <table>
        <tbody>
          {loading ? (
            <>
              {campanhasFiltradas.map((element) => {
                return (
                  <>
                    <div className="flex titulo">
                      <h2 className="nome-campanha">
                        #{element.campanha_id} - {element.campanha_nome}
                      </h2>
                      <a href={"campanha/detalhes/" + element.campanha_id}>
                        ver tudo&gt;
                      </a>
                    </div>
                    <div className="flex lista">
                      {element.campanha_material_briefing.map((elem) => {
                        elem.campanha_id = element.campanha_id;

                        return <CardCampanhaBriefing elem={elem} />;
                      })}
                      {vitrine &&
                        vitrine
                          .filter(
                            (elemen) =>
                              Number(elemen.campanha_id) ===
                              Number(element.campanha_id)
                          )
                          .map((elem) => (
                            <div
                              className="card"
                              onClick={() =>
                                navigate(
                                  `/campanha/detalhes/${elem.campanha_id}/${elem.id}`
                                )
                              }
                            >
                              <div className="flex-space">
                                <div className="top-card">
                                  <h3>Item: </h3>
                                  <span className="nome">
                                    {elem.material_vitrine}
                                  </span>
                                </div>
                                <div className="top-card">
                                  <h3>Origem: </h3>
                                  <span className="nome">Vitrine</span>
                                </div>
                              </div>

                              <div className="flex justify">
                                <h4>{elem.quantidade} unid.</h4>

                                {elem.status === "concluido" && (
                                  <h4 className="concluido">Concluído</h4>
                                )}
                                {elem.status === "em produção" && (
                                  <h4 className="produzindo">Em Produção</h4>
                                )}
                                {elem.status === "pendente" && (
                                  <h4 className="aguardando">
                                    Aguardando Análise
                                  </h4>
                                )}
                                {elem.status === "proposta" && (
                                  <h4 className="aguardando">
                                    Aguardando Análise
                                  </h4>
                                )}
                                {elem.status === "proposta aprovada" && (
                                  <h4 className="aguardando">
                                    Aguardando Análise
                                  </h4>
                                )}
                              </div>
                            </div>
                          ))}
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            // campanhasFiltradas.length > 0 ? (
            //   campanhasFiltradas.filter((lista) =>
            //     status
            //       ? briefings.some(
            //           (element) =>
            //             Number(element.campanhas_id) === Number(lista.id)
            //         )
            //       : true
            //   ).length > 0 ? (
            //     campanhasFiltradas
            //       .filter((lista) =>
            //         status
            //           ? briefings.some(
            //               (element) =>
            //                 Number(element.campanhas_id) === Number(lista.id)
            //             )
            //           : true
            //       )
            //       .map((lista) => (
            //         <>
            //           <div className="flex titulo">
            //             <h2 className="nome-campanha">
            //               #{lista.id} - {lista.nome}
            //             </h2>
            //             <a href={"campanha/detalhes/" + lista.id}>
            //               ver tudo&gt;
            //             </a>
            //           </div>
            //           <div className="flex lista">
            //             {briefings.length > 0 &&
            //               briefings
            //                 .filter(
            //                   (element) =>
            //                     Number(element.campanhas_id) ===
            //                     Number(lista.id)
            //                 )
            //                 .map((elem) => (
            //                   <CardCampanhaBriefing elem={elem} />
            //                 ))}
            //             {vitrine &&
            //               vitrine
            //                 .filter(
            //                   (element) =>
            //                     Number(element.campanha_id) === Number(lista.id)
            //                 )
            //                 .map((elem) => (
            //                   <div
            //                     className="card"
            //                     onClick={() =>
            //                       navigate(
            //                         `/campanha/detalhes/${elem.campanha_id}/${elem.id}`
            //                       )
            //                     }
            //                   >
            //                     <div className="flex-space">
            //                       <div className="top-card">
            //                         <h3>Item: </h3>
            //                         <span className="nome">
            //                           {elem.material_vitrine}
            //                         </span>
            //                       </div>
            //                       <div className="top-card">
            //                         <h3>Origem: </h3>
            //                         <span className="nome">Vitrine</span>
            //                       </div>
            //                     </div>

            //                     <div className="flex justify">
            //                       <h4>{elem.quantidade} unid.</h4>

            //                       {elem.status === "concluido" && (
            //                         <h4 className="concluido">Concluído</h4>
            //                       )}
            //                       {elem.status === "em produção" && (
            //                         <h4 className="produzindo">Em Produção</h4>
            //                       )}
            //                       {elem.status === "pendente" && (
            //                         <h4 className="aguardando">
            //                           Aguardando Análise
            //                         </h4>
            //                       )}
            //                       {elem.status === "proposta" && (
            //                         <h4 className="aguardando">
            //                           Aguardando Análise
            //                         </h4>
            //                       )}
            //                       {elem.status === "proposta aprovada" && (
            //                         <h4 className="aguardando">
            //                           Aguardando Análise
            //                         </h4>
            //                       )}
            //                     </div>
            //                   </div>
            //                 ))}
            //           </div>
            //         </>
            //       ))
            //   ) : (
            //     <h3 className="aviso">Nenhuma Campanha Encontrada! {":("}</h3>
            //   )
            // ) : (
            //   <h3 className="aviso">Nenhuma Campanha Encontrada! {":("}</h3>
            // )
            <Loading />
          )}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default Table;
