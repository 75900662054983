import { Link, useNavigate, useParams } from "react-router-dom";
import { Body, Inputs, NovoUsuarioContainer } from "./styles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../../../services/api";
import { CampoObrigatorio } from "../../../../schemas/novoCadastro";
import { toast } from "react-toastify";
import { useAuth } from "../../../../providers/Auth";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";

const EditarPerfil = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [permissoes, setPermissoes] = useState([]);
  const [permissoesVinculadas, setPermissoesVinculadas] = useState([]);
  const [permissoesSelecionadas, setPermissoesSelecionadas] = useState([]);

  function buscarPermissoes() {
    api
      .get("/permissoes")
      .then((response) => {
        setPermissoes(response.data.data.permissoes);
        buscarPermissoesVinculadas(response.data.data.permissoes);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarPermissoes();
  }, []);

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampoObrigatorio),
  });

  //ATUALIZAÇÃO PERFIL
  const onSubmitFunction = () => {
    const formValues = getValues();
    formValues.permissao = Number(formValues.permissao);
    api
      .patch(`/perfis/${id}`, formValues)
      .then((response) => {
        navigate("/perfis");
        toast.success("Perfil atualizado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
    const vinculos = {
      ...formValues,
      perfil_id: id,
      permissoes: permissoesSelecionadas,
    };
    api
      .post("/perfil-permissao/perfil", vinculos)
      .then((response) => {
        navigate("/perfis");
        toast.success("permissão criada com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const buscarPermissoesVinculadas = (permissoes) => {
    api
      .get(`/perfil-permissao/perfil/${id}`)
      .then((response) => {
        setPermissoesVinculadas(response.data.data.vinculos);
        const permissoesVinculadas = response.data.data.vinculos.map(
          (element) => element.permissao_id
        );

        const permissoesFiltradas = permissoes.filter(
          (element) => !permissoesVinculadas.includes(element.id)
        );
        setPermissoes(permissoesFiltradas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  const deletarPermissaoVinculada = (id) => {
    api
      .delete(`/perfil-permissao/${id}`)
      .then((response) => {
        toast.success("Permissão desvinculada com sucesso!");
        buscarPermissoes();
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  //BUSCA PERFIL PELO ID SEMRPE QUE ALTERA O ID
  useEffect(() => {
    api
      .get(`/perfis/${id}`)
      .then((response) => {
        reset(response.data.data.perfil);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  function adicionarPermissao(element) {
    if (
      permissoesSelecionadas.includes(Number(element)) === false &&
      element > 0
    ) {
      setPermissoesSelecionadas((prevSelecionadas) => [
        ...prevSelecionadas,
        Number(element),
      ]);
    }
  }

  function removerPermissao(id) {
    const filter = permissoesSelecionadas.filter((element) => element != id);
    setPermissoesSelecionadas(filter);
  }

  return (
    <NovoUsuarioContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/perfis")}
            className="voltar"
            src={voltar}
          />
          <div className="column">
            <h1>Perfil</h1>
            <h2>Editar perfil</h2>
          </div>
        </div>
        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              defaultChecked={true}
              {...register("status")}
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <div className="flex div-inputs">
            <Inputs>
              <div>
                <label>Nome do perfil</label>
                <input
                  type="text"
                  name="nome"
                  {...register("nome")}
                  placeholder="Nome do perfil"
                />
              </div>
            </Inputs>
            <Inputs>
              <div>
                <label>Permissão</label>
                <select
                  {...register("permissao")}
                  onClick={(e) => adicionarPermissao(e.target.value)}
                  {...register("marcas_id")}
                  name="permissao"
                  {...register("permissao")}
                >
                  <option>Selecione uma permissão</option>
                  {permissoes.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.nome}
                    </option>
                  ))}
                </select>
                {errors.nome && (
                  <span className="input-error-cadastro">
                    {errors.novo.message}
                  </span>
                )}
              </div>
            </Inputs>
          </div>
          <div className="flex wrap">
            {permissoesSelecionadas.length > 0 &&
              permissoesSelecionadas
                .map((elemen) =>
                  permissoes.filter((element) => element.id === elemen)
                )
                .map((elemento) => (
                  <div className="permissao">
                    <p className="nome-marca">{elemento[0].nome}</p>
                    <span onClick={() => removerPermissao(elemento[0].id)}>
                      X
                    </span>
                  </div>
                ))}
          </div>
        </Body>
        <h4>Permissões Vinculadas:</h4>
        <div className="flex wrap">
          {permissoesVinculadas.map((data) => (
            <div key={data.id} className="permissao">
              <p>{data.permissao}</p>
              <span onClick={() => deletarPermissaoVinculada(data.id)}>X</span>
            </div>
          ))}
        </div>
        <div className="buttons">
          <button type="submit">Salvar</button>
        </div>
      </div>
    </NovoUsuarioContainer>
  );
};

export default EditarPerfil;
