import { useForm } from "react-hook-form";
import { DivStyled } from "./style";
import { Input } from "../../../components/Input";
import Menu from "../../../components/Menu";
import Som from "../../../assets/campanha.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Botao from "../../../assets/voltar.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { campanhaSchema } from "../../../schemas/campanha";
import { useAuth } from "../../../providers/Auth";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Select from "react-select";
import Loading from "../../../components/Loading";

export const CriarCamapanha = () => {
  const { token, user } = useAuth();
  const [clienteId, setClienteId] = useState();
  const [marcas, setMarcas] = useState([]);
  const [marcasClientes, setMarcasClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [marcasSelecionadas, setMarcasSelecionadas] = useState([]);
  const [clientesResponsaveis, setClientesResponsaveis] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const adicionarHistorico = (dados) => {
    const data = {
      tipo: "Campanha",
      texto: `Campanha ${dados.nome} Criada`,
      campanha_id: dados.id,
    };

    api
      .post("/historico-campanha", [data])
      .then((response) => {})
      .catch((error) => {
        toast.error("Erro ao salvar no historico");
      });
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(campanhaSchema),
  });

  function buscarMarcas() {
    api
      .get("/marcas")
      .then((response) => {
        const arrayMarcas = response.data.data.marcas.map((element) => {
          return { value: element.id, label: element.nome };
        });
        setMarcas(arrayMarcas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function listarMarcasVinculadasCliente(id) {
    if (!id) {
      setMarcasClientes([]);
      setMarcasSelecionadas([]);
      return;
    }

    setLoading(true);
    api
      .get(`/marcas-cliente/${id}`)
      .then((response) => {
        const arrayMarcasClientes = response.data.data.vinculos.map(
          (element) => {
            return { value: element.id, label: element.nome };
          }
        );
        setMarcasClientes(arrayMarcasClientes);
        setMarcasSelecionadas([]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
        setLoading(false);
      });
  }

  function buscarCliente() {
    api
      .get(`/usuario-cliente/${user.id}`)
      .then((response) => {
        setClienteId(response.data.data.usuario.cliente_id);
        listarMarcasVinculadasCliente(response.data.data.usuario.cliente_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarClientesResponsaveis() {
    api
      .get(`/clientes/cliente/responsaveis`)
      .then((response) => {
        setClientesResponsaveis(response.data.data.clientes);
      })
      .catch((error) => {
        console.error(
          "Buscar Clientes Responsaveis na criação de campanha Erro:",
          error
        );
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.permissoes.includes("admin")) {
      buscarMarcas();
    }
    if (
      user.permissoes.includes("admin") ||
      user.permissoes.includes("atendimento")
    ) {
      buscarClientesResponsaveis();
    } else {
      buscarCliente();
    }
  }, [user]);

  useEffect(() => {
    listarMarcasVinculadasCliente(clienteSelecionado);
  }, [clienteSelecionado]);

  const criarCampanha = (data) => {
    if (marcasSelecionadas.length === 0) {
      toast.warning("Selecione ao menos uma marca.");
      return;
    }

    if (!data.prazo_final || typeof data.prazo_final !== "string") {
      toast.error("Prazo final inválido ou não fornecido.");
      return;
    }

    if (/^\d{4}-\d{2}-\d{2}$/.test(data.prazo_final)) {
      const partes = data.prazo_final.split("-");
      data.prazo_final = `${partes[2]}/${partes[1]}/${partes[0]}`;
    }

    data.cliente_id = data.cliente_id || clienteId;
    data.status = 1;

    if (!data.cliente_id) {
      toast.error("Selecione um cliente.");
      return;
    }
    api
      .post(
        "/campanhas",
        { campanhas: [data] },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        adicionarHistorico(response.data.data.campanha);
        const arrayTransformado = marcasSelecionadas.map((item) => {
          return {
            marca_id: item.value,
            campanha_id: response.data.data.insertId,
          };
        });

        api
          .post(
            "/campanha-marca",
            { vinculos: arrayTransformado },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            toast.success("Campanha Criada! 🎉");
            setTimeout(() => {
              navigate(
                `/campanha/detalhes/${arrayTransformado[0].campanha_id}`
              );
            }, 2000);
          })
          .catch((error) => {
            toast.error("Algo deu Errado. Tente novamente.");
          });
      })
      .catch((error) => {
        toast.error("Algo deu Errado. Tente novamente.");
      });
  };

  return (
    <DivStyled>
      <Menu />
      {user.permissoes.includes("admin") ||
      user.permissoes.includes("atendimento") ? (
        <div className="box-right">
          <div className="flex">
            <ReactSVG
              onClick={() => navigate("/campanhas")}
              src={Botao}
              className="cursor"
            />
            <ReactSVG src={Som} alt="" className="som" />
            <h1>Nova Campanha</h1>
          </div>
          <form onSubmit={handleSubmit(criarCampanha)}>
            <div className="div-select">
              <label>Cliente</label>
              <select
                {...register("cliente_id")}
                onChange={(e) => {
                  setClienteSelecionado(e.target.value);
                }}
              >
                <option value="">Selecionar Cliente</option>
                {clientesResponsaveis &&
                  clientesResponsaveis.map((cliente) => {
                    return (
                      <option value={cliente.id}>
                        {cliente.nome_fantasia}
                      </option>
                    );
                  })}
              </select>
            </div>
            <Input
              label="Nome da campanha"
              type="text"
              placeholder="Nome da campanha"
              register={register("nome")}
              className="input width95"
            />
            {errors.nome && (
              <span className="input-error">{errors.nome.message}</span>
            )}
            <div className="flex-input">
              <div className="div-select">
                <label>Marca</label>
                <Select
                  isMulti={true}
                  onChange={setMarcasSelecionadas}
                  options={marcasClientes.length > 0 ? marcasClientes : marcas}
                />
                {/* <select
                  onClick={(e) => adicionarMarca(e.target.value)}
                  {...register("marcas_id")}
                >
                  <option value="0">Selecionar a marca</option>
                  {marcas.length > 0 &&
                  marcasClientes.length > 0 &&
                  user.permissoes.includes("admin")
                    ? marcas.map((element) => (
                        <option value={element.id}>{element.nome}</option>
                      ))
                    : marcas.length > 0 &&
                      marcasClientes.length > 0 &&
                      marcasClientes.map((element) => (
                        <option
                          value={
                            marcas.find((elem) => elem.id == element.marca_id)
                              .id
                          }
                        >
                          {
                            marcas.find((elem) => elem.id == element.marca_id)
                              .nome
                          }
                        </option>
                      ))}
                </select> */}
              </div>
              <div className="div-error">
                <Input
                  className="input"
                  label="Prazo final (data máxima da entrega da campanha)"
                  type="date"
                  placeholder="DD/MM/AAAA"
                  register={register("prazo_final")}
                />
                {errors.prazo_final && (
                  <span className="input-error">
                    {errors.prazo_final.message}
                  </span>
                )}
              </div>
            </div>
            <button>Confirmar</button>
          </form>
        </div>
      ) : loading ? (
        <Loading />
      ) : (
        <div className="box-right">
          <div className="flex">
            <ReactSVG onClick={() => navigate("/campanhas")} src={Botao} />
            <ReactSVG src={Som} alt="" className="som" />
            <h1>Nova Campanha</h1>
          </div>
          <form onSubmit={handleSubmit(criarCampanha)}>
            <Input
              label="Nome da campanha"
              type="text"
              placeholder="Nome da campanha"
              register={register("nome")}
              className="input width95"
            />
            {errors.nome && (
              <span className="input-error">{errors.nome.message}</span>
            )}
            <div className="flex-input">
              <div className="div-select">
                <label>Marca</label>
                <Select
                  isMulti={true}
                  onChange={setMarcasSelecionadas}
                  options={marcasClientes}
                />
              </div>
              <div className="div-error">
                <Input
                  className="input"
                  label="Prazo final (data máxima da entrega da campanha)"
                  type="date"
                  placeholder="DD/MM/AAAA"
                  register={register("prazo_final")}
                />
                {errors.prazo_final && (
                  <span className="input-error">
                    {errors.prazo_final.message}
                  </span>
                )}
              </div>
            </div>
            <button>Confirmar</button>
          </form>
        </div>
      )}
    </DivStyled>
  );
};
