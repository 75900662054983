import * as yup from "yup";

// yup.addMethod(yup.string, "validarDataNascimento", function (msg) {
//   return this.test("validarDataNascimento", msg, function (value) {
//     const { path, createError } = this;

//     if (!value) {
//       return true;
//     }

//     const daysInMonth = (m, y) => {
//       switch (m) {
//         case 1:
//           return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
//         case 8:
//         case 3:
//         case 5:
//         case 10:
//           return 30;
//         default:
//           return 31;
//       }
//     };

//     const [dia, mes, ano] = value.split("/");
//     if (!dia || !mes || !ano) {
//       return createError({ path, message: msg });
//     }
//     if (
//       Number(dia) < 1 ||
//       Number(dia) > daysInMonth(Number(mes), Number(ano))
//     ) {
//       return createError({ path, message: msg });
//     }
//     if (Number(mes) < 1 || Number(mes) > 12) {
//       return createError({ path, message: msg });
//     }
//     if (ano.length < 4) {
//       return createError({ path, message: msg });
//     }
//     const dataNascimento = new Date(ano, mes - 1, dia);
//     const dataAtual = new Date();
//     const diferencaTempo = dataAtual.getTime() - dataNascimento.getTime();
//     const idadeEmAnos = diferencaTempo / (1000 * 60 * 60 * 24 * 365.25);
//     if (idadeEmAnos < 18) {
//       return createError({ path, message: msg });
//     }

//     return value;
//   });
// });

export const campanhaSchema = yup.object().shape({
  nome: yup.string().required("Campo obrigatório (Nome)."),
  prazo_final: yup
    .string("Precisa ser uma data")
    .required("Campo obrigatório."),
});

export const briefingSchema = yup.object().shape({
  nome: yup
    .string()
    .max(200, "Máximo de 200 caracteres")
    .required("Preencha o campo"),
  descricao: yup.string().required("Preencha o campo"),
  substrato_predominante: yup.string().required("Preencha o campo"),
  largura: yup.string().required("Deve ser um Número"),
  altura: yup.string("Deve ser um Número").required("Deve ser um Número"),
  comprimento: yup.string().required("Preencha o campo"),
  cor: yup.string().required("Preencha o campo"),
  corte: yup.boolean().required("Preencha o campo"),
  prazo_maximo: yup.string().required("Preencha o campo"),
  endereco: yup.string().required("Preencha o campo"),
  categoria: yup.string().required("Preencha o Campo"),
  file: yup.mixed().test("is-file", "Campo obrigatório", (value) => {
    if (value.length != 0) {
      return true;
    }
    return false;
  }),
});

export const schemaComunicacaoVisual = yup.object().shape({
  acabamentos: yup.string().required("Acabamentos obrigatórios"),
  cor: yup.string().required("Cor obrigatória"),
  descricao: yup.string().required("Descrição obrigatória"),
  endereco: yup.string().required("Endereço obrigatório"),
  file: yup.mixed().required("Arquivo obrigatório"),
  formato_aberto: yup.string().required("Formato aberto obrigatório"),
  gramatura: yup.string().required("Gramatura obrigatória"),
  instalacao: yup.boolean().required("Instalação obrigatória"),
  nome: yup.string().required("Nome obrigatório"),
  prazo_maximo: yup.string().required("Prazo máximo obrigatório"),
  substrato_material: yup.string().required("Substrato/Material obrigatório"),
});

export const schemaPontoDeVenda = yup.object().shape({
  espessura: yup.string().required("Espessura obrigatória"),
  acabamentos: yup.string().required("Acabamentos obrigatórios"),
  cor: yup.string().required("Cor obrigatória"),
  descricao: yup.string().required("Descrição obrigatória"),
  endereco: yup.string().required("Endereço obrigatório"),
  file: yup.mixed().required("Arquivo obrigatório"),
  tamanho: yup.string().required("Tamanho obrigatório"),
  nome: yup.string().required("Nome obrigatório"),
  prazo_maximo: yup.string().required("Prazo máximo obrigatório"),
  substrato_material: yup.string().required("Substrato/Material obrigatório"),
  positivacao: yup.boolean().required("Instalação obrigatória"),
});

export const EditarCampanhaSchema = yup.object().shape({
  prazo: yup.string("Precisa ser uma data").required("Campo obrigatório."),
});
