import { useEffect, useState } from "react";
import { useAuth } from "../../../providers/Auth";
import { AddBriefingVitrineStyle } from "./style";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { ReactSVG } from "react-svg";
import Botao from "../../../assets/voltar.svg";
import { TabelaItemVitrine } from "../../TabelaItemVitrine";

export const AddBriefingVitrine = ({ state, id }) => {
  const [orcamentos, setOrcamentos] = useState([]);
  const [bloquear, setBloquear] = useState(false);
  const [materialBriefing, setMaterialBriefing] = useState([]);
  const [quantidade, setQuantidade] = useState();
  const [campanha, setCampanha] = useState();
  const [marcas, setMarcas] = useState([]);
  const [categoriasFornecedor, setCategoriasFornecedor] = useState([]);
  const [item, setItem] = useState();
  const [ncm, setNcm] = useState([]);
  const { reset, register } = useForm();
  const { user } = useAuth();

  function buscarOrcamentoBriefing(material_id) {
    if (!user) {
      return;
    }
    api
      .get(`/orcamento-materiais-briefing`)
      .then((response) => {
        const dataOrcamentos = response.data.data.orcamentos;
        const filterOrcamentos = dataOrcamentos.filter(
          (element) => element.campanha_material_briefing_id == material_id
        );
        const verificarConcluao = filterOrcamentos.filter(
          (element) =>
            element.status === "aprovado" ||
            element.status === "proposta aprovada"
        );

        if (verificarConcluao.length > 0) {
          setBloquear(true);
        }
        setOrcamentos(filterOrcamentos);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarItens(id) {
    api
      .get(`/itens/${id}`)
      .then((response) => {
        setItem(response.data.data.item);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function quantidadeDeCotacao(quantidade) {
    setQuantidade(quantidade);
  }

  function buscarMarcas(id) {
    api
      .get(`/campanha-marca/${id}`)
      .then((response) => {
        const dataMarcas = response.data.data.vinculos;
        const filterMarcas = dataMarcas.filter(
          (element) => Number(element.campanha_id) === Number(id)
        );
        setMarcas(filterMarcas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhas(material_id) {
    api
      .get(`/campanhas`)
      .then((response) => {
        const dataCampanhas = response.data.data.campanhas;
        const filterCampanhas = dataCampanhas.filter(
          (element) => element.id == material_id
        )[0];
        buscarMarcas(filterCampanhas.id);
        setCampanha(filterCampanhas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMaterialBriefing() {
    api
      .get("/campanha-material-briefing")
      .then((response) => {
        const dataMateriais = response.data.data.materiais;
        const filterMaterial = dataMateriais.filter(
          (element) => element.id == id
        )[0];
        setMaterialBriefing(filterMaterial);
        reset({
          quantidade: filterMaterial.quantidades[0],
          fee: filterMaterial.fee
            ? filterMaterial.fee
            : filterMaterial.cliente_fee,
        });
        setQuantidade(filterMaterial.quantidades[0]);
        buscarItens(filterMaterial.item_id);
        buscarOrcamentoBriefing(filterMaterial.id);
        buscarCampanhas(filterMaterial.campanhas_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarNCM() {
    api
      .get(`/ncm`)
      .then((response) => {
        setNcm(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCategoriaFornecedor() {
    api
      .get(`/categoria-fornecedor`)
      .then((response) => {
        setCategoriasFornecedor(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarMaterialBriefing();
    buscarNCM();
    buscarCategoriaFornecedor();
  }, []);

  return (
    <AddBriefingVitrineStyle>
      <div className="white">
        <span className="close" onClick={() => state(!state)}>
          X
        </span>
        <div className="overflow">
          {materialBriefing && campanha ? (
            <div className="disputa">
              <div className="body">
                <div className="div-detalhes">
                  <div className="flex">
                    <ReactSVG
                      className="back"
                      onClick={() => window.history.back()}
                      src={Botao}
                    />
                    <div className="detalhes">
                      <h1># {campanha && campanha.id}</h1>
                      <h2 className="nome-campanha">
                        {Object.keys(campanha).length != 0 && campanha.nome}
                      </h2>
                    </div>
                  </div>
                  <div className="column">
                    <div className="div-dados">
                      <div className="flex margin-right">
                        <h3>Cliente:</h3>
                        <h4>{materialBriefing.cliente_nome}</h4>
                      </div>
                      <div className="flex margin-right">
                        <h3>
                          Solicitante: {materialBriefing.solicitante_nome}
                        </h3>
                        <h4></h4>
                      </div>
                      <div className="flex margin-right">
                        <h3>Marca:</h3>
                        {marcas &&
                          marcas.map((element, index) => (
                            <h4 className="marcas">
                              {index > 0 ? "| " + element.nome : element.nome}{" "}
                            </h4>
                          ))}
                      </div>
                    </div>
                    {!materialBriefing.prazo_propostas ? (
                      <div className="prazo">
                        <h2>Prazo final de envio das propostas:</h2>
                        <div>
                          <input
                            type="datetime-local"
                            {...register("prazo_propostas")}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="prazo_proposta">
                        <div>
                          <div className="aling-itens">
                            <h2>Prazo final de envio das propostas:</h2>
                            <h3>{materialBriefing.prazo_propostas}</h3>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="faixa">
                  <div className="flex">
                    <h4>Material :</h4>
                    <h2>
                      {" "}
                      {Object.keys(materialBriefing).length != 0 &&
                        "#" +
                        materialBriefing.id +
                        " " +
                        materialBriefing.nome}{" "}
                      {materialBriefing.subnome && materialBriefing.subnome}
                    </h2>
                  </div>
                </div>
                <div className="flex div-detalhes-proposta">
                  <div className="column div-especificao">
                    <h3>Especificação:</h3>
                    {item && item.tipo === "Serviço" ? (
                      materialBriefing.descricao
                    ) : (
                      <h4>
                        {materialBriefing.categoria_item &&
                          materialBriefing.categoria_item === "Brindes"
                          ? ` Descrição: ${materialBriefing.observacao
                          } | Público Alvo: ${materialBriefing.publico_alvo
                          } | Item de Marca: ${materialBriefing.item_de_marca ? "Sim" : "Não"
                          } | Quantidades:  [${materialBriefing.quantidades.map(
                            (element) => element
                          )}]`
                          : materialBriefing.categoria_item ===
                            "Material Impresso"
                            ? ` Descrição: ${materialBriefing.observacao
                            } | Quantidade de páginas: ${materialBriefing.quantidade_de_paginas
                            } | Formato aberto: ${materialBriefing.formato_aberto
                            }cm | Formato fechado: ${materialBriefing.formato_fechado
                            }cm | Tipo de Papel: ${materialBriefing.substrato
                            } | Gramatura: ${materialBriefing.gramatura
                            } | Cores: ${materialBriefing.cores
                            } | Acabamentos: ${materialBriefing.acabamentos
                            } | Quantidades:  | Quantidades:  [${materialBriefing.quantidades.map(
                              (element) => element
                            )}]`
                            : materialBriefing.categoria_item == "Ponto de Venda"
                              ? ` Descrição: ${materialBriefing.observacao
                              } | Tamanho Final: ${materialBriefing.tamanho
                              } | Substrato: ${materialBriefing.substrato
                              } | Espessura: ${materialBriefing.espessura
                              } | Cores: ${materialBriefing.cores
                              } | Acabamentos: ${materialBriefing.acabamentos
                              } | Necessário Positivação: ${materialBriefing.positivacao ? "Sim" : "Não"
                              } | Quantidades:  [${materialBriefing.quantidades.map(
                                (element) => element
                              )}]`
                              : materialBriefing.categoria_item ===
                                "Comunicação Visual"
                                ? ` Descrição: ${materialBriefing.descricao_produtor
                                } | Tamanho Final: ${materialBriefing.tamanho
                                } | Formato aberto: ${materialBriefing.formato_aberto
                                }cm | Substrato: ${materialBriefing.substrato
                                } | Gramatura: ${materialBriefing.gramatura
                                } | Cores: ${materialBriefing.cores
                                } | Acabamentos: ${materialBriefing.acabamentos
                                } | Necessário Positivação: ${materialBriefing.positivacao ? "Sim" : "Não"
                                }`
                                : ""}
                      </h4>
                    )}
                  </div>
                  <div className="column div-tipo">
                    <h3>Tipo</h3>
                    <h4>
                      {Object.keys(materialBriefing).length != 0 &&
                        materialBriefing.categoria_item}
                    </h4>
                  </div>
                  <div className="column div-ncm">
                    {item && item.tipo === "Serviço" ? (
                      <>
                        <h3>ISS</h3>
                        <h4>{item.iss}</h4>
                      </>
                    ) : (
                      <>
                        <h3>NCM</h3>
                        <h4>
                          {ncm?.length > 0 &&
                            item &&
                            Object.keys(item).length != 0 &&
                            ncm.find((element) => element.id == item.ncm_id)
                              .codigo}
                        </h4>
                      </>
                    )}
                  </div>
                  <div className="div-quantidade">
                    <h3>Quantidade para cotação:</h3>
                    <div className="flex">
                      <select
                        {...register("quantidade")}
                        className="select"
                        onChange={(e) => quantidadeDeCotacao(e.target.value)}
                      >
                        {materialBriefing.quantidades &&
                          materialBriefing.quantidades.map((quantidade) => (
                            <option>{quantidade}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    {materialBriefing.fee ? (
                      <div className="div-fee">
                        <h5>Fee Específico para o JOB:</h5>
                        <div className="flex">
                          <input
                            type="number"
                            {...register("fee")}
                            disabled={
                              orcamentos
                                .filter(
                                  (element) => element.quantidade == quantidade
                                )
                                .filter(
                                  (el) =>
                                    el.status === "aprovado" ||
                                    el.status === "proposta aprovada"
                                ).length > 0
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {ncm.length > 0 && materialBriefing && item && (
            <TabelaItemVitrine
              orcamentos={orcamentos.filter(
                (element) => element.quantidade == quantidade
              )}
              buscarMaterialBriefing={buscarMaterialBriefing}
              materialBriefing={materialBriefing}
              ncm={ncm.find((element) => element.id == item.ncm_id)}
              fee={materialBriefing.cliente_fee}
              categoriasFornecedor={categoriasFornecedor}
              item={item}
              bloquear={bloquear}
              quantidade={quantidade}
              idMaterial={materialBriefing.id}
            />
          )}
        </div>
      </div>
    </AddBriefingVitrineStyle>
  );
};
