import { useEffect, useState } from "react";
import { Tr } from "./styles";
import api from "../../../../services/api";
import { moneyMask } from "../../../../utils/masks";
import { toast } from "react-toastify";

const PropostaCampanhaTr = ({
  origem,
  material,
  quantidade,
  setMateriaisProposta,
  ultimo,
  ncms,
  itens,
  materiaisProposta,
}) => {
  const [orcamentosMaterialBriefing, setOrcamentosMaterialBriefing] = useState(
    []
  );

  const item = itens.find(
    (elem) => Number(elem.id) === Number(material.item_id)
  );

  const ncm = item
    ? ncms.find((elem) => Number(elem.id) === Number(item.ncm_id))
    : "";
  const [orcamentoSelecionado, setOrcamentoSelecionado] = useState("");

  const [orcamentosLoaded, setOrcamentosLoaded] = useState(false);
  const [orcamentoSelecionadoLoaded, setOrcamentoSelecionadoLoaded] =
    useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (orcamentosLoaded && orcamentoSelecionadoLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [orcamentosLoaded, orcamentoSelecionadoLoaded]);

  const buscarOrcamentosMaterialBriefing = () => {
    api
      .get(`/orcamento-materiais-briefing/${material.id}`)
      .then((response) => {
        const orcamentosAprovados = response.data.data.orcamentos.filter(
          (orcamento) =>
            orcamento.status === "aprovado" ||
            orcamento.status === "proposta aprovada" ||
            orcamento.status === "em produção" ||
            orcamento.status === "concluido"
        );
        setOrcamentosMaterialBriefing(orcamentosAprovados);
        setOrcamentosLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar orçamentos.");
      });
  };

  useEffect(() => {
    if (origem === "Briefing") {
      buscarOrcamentosMaterialBriefing();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (orcamentosMaterialBriefing.length) {
      setOrcamentoSelecionado(
        orcamentosMaterialBriefing.find(
          (orcamento) => orcamento.quantidade === quantidade
        )
      );
      setOrcamentoSelecionadoLoaded(true);
    } else {
      setOrcamentoSelecionadoLoaded(true);
    }
  }, [orcamentosMaterialBriefing]);

  const selecionarMaterial = (e) => {
    let dadosMaterial = {
      nome_material: "",
      descricao_material: "",
      quantidade: "",
      icms: "",
      iss: "",
      valor_unidade: "",
      valor_total: "",
      valor_de_venda: "",
    };
    if (origem === "Briefing") {
      dadosMaterial = {
        material_id: material.id,
        orcamento_id: orcamentoSelecionado.id,
        nome_material: orcamentoSelecionado.campanha_material_briefing,
        descricao_material: material.descricao_produtor,
        origem: "Briefing",
        quantidade: orcamentoSelecionado.quantidade,
        prazo_entrega: orcamentoSelecionado.prazo_producao,
        icms: orcamentoSelecionado.icms,
        valor_unidade: orcamentoSelecionado.valor_unidade,
        valor_de_venda: orcamentoSelecionado.valor_de_venda,
        valor_total:
          material.tipo === "Serviço"
            ? orcamentoSelecionado.valor_total
            : Number(orcamentoSelecionado.valor_unidade) *
              orcamentoSelecionado.quantidade,
        fornecedor_id: orcamentoSelecionado.fornecedor_id,
        ipi: orcamentoSelecionado.ipi,
        iss: material.iss,
        subnome: material.subnome,
      };
    } else {
      dadosMaterial = {
        material_id: material.id,
        nome_material: material.material_vitrine,
        descricao_material: material.observacao,
        origem: "Vitrine",
        quantidade: material.quantidade,
        valor_unidade: material.preco_unitario,
        valor_de_venda: orcamentoSelecionado.valor_de_venda,
        valor_total:
          Number(material.preco_unitario) * Number(material.quantidade),
        fornecedor_id: material.fornecedor_id,
        icms: material.imposto,
      };
    }
    if (e.target.checked) {
      setMateriaisProposta((prevState) => [...prevState, dadosMaterial]);
    } else {
      setMateriaisProposta((prevState) =>
        prevState.filter((materialProposta) => {
          if (origem === "Briefing") {
            return materialProposta.orcamento_id !== orcamentoSelecionado.id;
          } else {
            return materialProposta.material_id !== material.id;
          }
        })
      );
    }
  };
  return (
    <Tr $ultimo={ultimo}>
      <td>
        <p>{origem}</p>
      </td>
      <td>
        {origem === "Briefing" ? (
          <p>
            {material.nome} {material.subnome}
          </p>
        ) : (
          <p>{material.material_vitrine}</p>
        )}
      </td>
      <td>
        {origem === "Briefing" ? (
          <>
            <p>{quantidade} un.</p>
          </>
        ) : (
          <p>{material.quantidade} un.</p>
        )}
      </td>
      <td>
        {origem === "Briefing" ? (
          loading ? (
            <div className="loading"></div>
          ) : orcamentoSelecionado ? (
            <p>
              R${" "}
              {moneyMask(
                String(
                  Number(
                    orcamentoSelecionado.valor_de_venda /
                      orcamentoSelecionado.quantidade
                  ).toFixed(2)
                )
              )}
            </p>
          ) : (
            <>-</>
          )
        ) : (
          <p>
            R$ {moneyMask(String(Number(material.preco_unitario).toFixed(2)))}
          </p>
        )}
      </td>
      <td>
        {origem === "Briefing" ? (
          loading ? (
            <div className="loading">
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : orcamentoSelecionado ? (
            <p>
              R${" "}
              {moneyMask(
                String(Number(orcamentoSelecionado.valor_de_venda).toFixed(2))
              )}
            </p>
          ) : (
            <>-</>
          )
        ) : (
          <p>
            R${" "}
            {moneyMask(
              String(
                Number(material.preco_unitario * material.quantidade).toFixed(2)
              )
            )}
          </p>
        )}
      </td>
      <td>
        {origem === "Briefing" ? (
          material.status == "enviado pra orçamento" ||
          material.status == "pendente" ? (
            "Aguardando Revisão"
          ) : orcamentoSelecionado ? (
            orcamentoSelecionado.status == "aprovado" ? (
              "Esperando Confirmação"
            ) : (
              orcamentoSelecionado.status
            )
          ) : (
            <>Aguardando Análise</>
          )
        ) : (material && material.status === "proposta aprovada") ||
          material.status === "em produção" ||
          material.status === "concluido" ? (
          material.status
        ) : (
          "Aguardando Análise"
        )}
      </td>
      <td>
        {origem === "Briefing" ? (
          loading ? (
            <div className="loading"></div>
          ) : (
            <>
              {orcamentoSelecionado ? (
                orcamentoSelecionado.status === "proposta aprovada" ||
                orcamentoSelecionado.status === "em produção" ||
                orcamentoSelecionado.status === "concluido" ? (
                  <div className="aprovado">
                    <p>Aprovado</p>
                    <span>em {material.data_atualizacao.split(" ")[0]}</span>
                  </div>
                ) : orcamentoSelecionado.status === "em produção" ? (
                  <div className="aprovado">
                    <p>Aprovado</p>
                    <span>em {material.data_atualizacao.split(" ")[0]}</span>
                  </div>
                ) : (
                  <input
                    type="checkbox"
                    disabled={!orcamentoSelecionado}
                    onClick={selecionarMaterial}
                    value={"lucas"}
                  />
                )
              ) : (
                <input
                  type="checkbox"
                  disabled={!orcamentoSelecionado}
                  onClick={selecionarMaterial}
                  value={"lucas"}
                />
              )}
            </>
          )
        ) : material.status === "proposta aprovada" ||
          material.status === "em produção" ||
          material.status === "concluido" ? (
          <div className="aprovado">
            <p>Aprovado</p>
            <span>em {material.data_atualizacao.split(" ")[0]}</span>
          </div>
        ) : (
          <input type="checkbox" onClick={selecionarMaterial} />
        )}
      </td>
    </Tr>
  );
};

export default PropostaCampanhaTr;
