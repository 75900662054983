import { styled } from "styled-components";

export const NovoUsuarioContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;

  .box {
    margin-top: 40px;
    width: 90%;
    min-height: 300px;
    background-color: var(--base);
    border-radius: 18px;

    h1 {
      font-size: 0.8rem;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .flex {
      align-items: center;
    }

    h1 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 5px;
      margin-left: 10px;
      color: var(--principal);
    }

    .permissao {
      font-size: 0.8rem;
      font-weight: 700;
      margin-right: 20px;
      position: relative;

      p {
        background-color: aliceblue;
        border: 6px;
        padding: 5px;
      }

      span {
        position: absolute;
        right: -10px;
        top: -20px;
        color: var(--principal);
        font-weight: 700;
        cursor: pointer;
      }
    }

    h2 {
      font-size: 14px;
      text-decoration: underline;
      color: var(--principal);
      margin-left: 11px;
    }

    h4 {
      font-weight: 700;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .wrap {
      flex-wrap: wrap;
      gap: 25px;
    }

    .voltar {
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .buttons {
      margin-top: 50px;
      display: flex;
      align-items: center;
      gap: 20px;
      a {
        font-size: 0.9rem;
      }
      button {
        background-color: var(--amarelo);
        color: var(--principal);
        border: none;
        padding: 5px;
        border-radius: 20px;
        width: 10%;
        font-size: 0.8rem;
        font-weight: 700;
      }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  .div-inputs {
    justify-content: space-between;
  }

  .checkbox input {
    display: none;
  }

  .checkbox input + label:before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid #979696;
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    color: var(--principal);
    cursor: pointer;
  }

  .checkbox input:checked + label:before {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
    background-color: var(--amarelo);
    color: var(--principal);
  }

  .checkbox label {
    font-size: 0.9rem;
  }

  h2 {
    margin: 20px 0px 30px 0px;
  }

  > label {
    margin-bottom: 10px;
    font-size: 0.8rem;
  }

  > input {
    border: none;
    background-color: var(--grayF3);
    padding: 10px;
    border-radius: 6px;
    font-size: 0.8rem;
    outline: none;
  }
`;

export const Inputs = styled.div`
  display: flex;
  align-items: center;
  width: 45%;
  margin: 20px 0 20px 0;
  gap: 50px;
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }
`;
