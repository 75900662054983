import { useForm } from "react-hook-form";
import {
  FormPropostaFornecedorPageStyle,
  FormStyle,
  TabelaStyle,
  TableContainer,
} from "./style";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";
import { useEffect, useState } from "react";
import { ConfirmacaoDeOrcamento } from "../../Modal/ConfirmacaoDeOrcamento";
import { useParams } from "react-router-dom";
import Loading from "../../Loading";
import GerarPedidoComprasModal from "../../Modal/GerarPedidoComprasModal";
import GerarPedidoOrcamentoModal from "../../Modal/GerarPedidoOrcamentoModal";

export const FormPropostaFornecedorPage = ({
  element,
  index,
  length,
  campanha,
  orcamentos,
  fornecedor,
  dadosFornecedor,
  admin,
  buscarMaterialBriefing,
  quantidade,
}) => {
  const { token, user } = useAuth();
  const { id } = useParams();
  const { register, handleSubmit, reset, getValues, setValue } = useForm();
  const [travarBotao, setTravarBotao] = useState(false);

  const [abrirModal, setAbrirModal] = useState(false);

  const filterOrcamentos = orcamentos?.filter(
    (elemen) =>
      (elemen.campanha_material_briefing_id == element.id &&
        fornecedor.fornecedor_id) ||
      (admin === true &&
        elemen.campanha_material_briefing_id == element.id &&
        fornecedor.id &&
        elemen.quantidade == quantidade)
  );

  const impostosBaseGerais = filterOrcamentos?.filter(
    (elem) => elem.status !== "Não Participa"
  )[0];

  const dadosNcm = impostosBaseGerais
    ? {
        icms: impostosBaseGerais.icms,
        ipi: impostosBaseGerais.ipi,
        icms_st: impostosBaseGerais.icms_st,
      }
    : { icms: element.icms_sp, ipi: element.ncm_ipi, icms_st: element.icms_st };

  const [propostas, setPropostas] = useState([]);
  const [propostaMateriais, setPropostaMateriais] = useState([]);
  const [propostaMateriaisLoaded, setPropostaMateriaisLoaded] = useState(false);
  const [materialPedidoCompras, setMaterialPedidoCompras] = useState(false);
  const [materialPedidoOrcamento, setMaterialPedidoOrcamento] = useState(false);
  const [material, setMaterial] = useState();
  const [fotosSelecionadas, setFotosSelecionadas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [arrayMaterialDistribuicoes, setArrayMaterialDistribuicoes] = useState(
    []
  );
  const [arquivoImpressao, setArquivoImpressao] = useState();

  useEffect(() => {
    if (propostaMateriaisLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [propostaMateriaisLoaded]);

  const colunas = [
    "Quantidade para cotação",
    "Não Participar",
    "Valor unitário",
    "Valor total",
    "Prazo de produção",
  ];

  const getDataUTC = (utc) => {
    const dataAtual = new Date();
    const dataNaoFormatada = new Date(dataAtual.valueOf() - utc * 60 * 60000);

    const { dia, mes, anoCompleto, hora, minuto } = {
      dia: dataNaoFormatada.getUTCDate().toString().padStart(2, "0"),
      mes: (dataNaoFormatada.getUTCMonth() + 1).toString().padStart(2, "0"),
      anoCompleto: dataNaoFormatada.getFullYear(),
      hora: dataNaoFormatada.getUTCHours().toString().padStart(2, "0"),
      minuto: dataNaoFormatada.getUTCMinutes().toString().padStart(2, "0"),
    };
    const dataFormatada = [dia, mes, anoCompleto].join("/");
    const horaFormatada = [hora, minuto].join(":");
    return dataFormatada + " " + horaFormatada;
  };

  useEffect(() => {
    if (dadosNcm == undefined) {
      return;
    }

    reset(dadosNcm);
  }, []);

  function converterFormatoData(dataOriginal) {
    const dataFormatada = dataOriginal.replace(
      /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/,
      "$3-$2-$1T$4:$5:00"
    );

    return dataFormatada;
  }

  const dataAtual = converterFormatoData(getDataUTC(3));

  const prazoMaximoDeProposta = converterFormatoData(element.prazo_propostas);

  function verificarData() {
    if (dataAtual > prazoMaximoDeProposta) {
      toast.error("Prazo de orçamento Expirado");
    }
  }

  function criarOrcamento(data) {
    for (let i = 0; i < element.quantidades.length; i++) {
      if (!data[`participar_${element.quantidades[i].quantidade}`]) {
        if (!data[`valor_unitario_${element.quantidades[i].quantidade}`]) {
          return toast.error(
            `Adicione um valor na Quantidade ${element.quantidades[i].quantidade}, ou clique em não participar`
          );
        }
      }
    }

    for (let i = 0; i < element.quantidades.length; i++) {
      if (!data.prazo) {
        return toast.error("Adicione a Validade desse Orçamento");
      }

      if (dataAtual > prazoMaximoDeProposta) {
        return toast.error("Prazo de orçamento Expirado");
      }

      setTravarBotao(true);

      if (!data[`participar_${element.quantidades[i].quantidade}`]) {
        const objeto = {
          material_briefing_quantidades_id: element.quantidades[i].id,
          valor_unidade:
            data[`valor_unitario_${element.quantidades[i].quantidade}`],
          valor_total: data[`valor_total_${element.quantidades[i].quantidade}`],
          observacoes: data.observacao,
          prazo_producao: data[`prazo_${element.quantidades[i].quantidade}`],
          ipi: data.ipi,
          icms: dadosFornecedor.icms,
          icms_st: data.icms_st,
          categoria: data.categoria,
          estado: "1",
          fornecedor_id: admin ? fornecedor.id : fornecedor.fornecedor_id,
          campanha_material_briefing_id: element.id,
          criador_id: 1,
          data_entrega: data[`prazo_${element.quantidades[i].quantidade}`],
          status: "pendente",
          validade_orcamento: data.prazo,
        };

        api
          .post(
            "/orcamento-materiais-briefing",
            { orcamentos: [objeto] },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {})
          .catch((error) => {
            console.error("Criar Orcamento Erro:", error);
            toast.error("Erro ao criar orçamento😢. Tente novamente.");
          });
      } else {
        const objeto = {
          material_briefing_quantidades_id: element.quantidades[i].id,
          valor_unidade: 0,
          valor_total: "",
          observacoes: "",
          prazo_producao: "",
          ipi: "",
          icms: "",
          icms_st: "",
          categoria: "",
          estado: "",
          fornecedor_id: admin ? fornecedor.id : fornecedor.fornecedor_id,
          campanha_material_briefing_id: element.id,
          criador_id: user.id,
          data_entrega: "",
          status: "Não Participa",
        };

        api
          .post(
            "/orcamento-materiais-briefing",
            { orcamentos: [objeto] },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {})
          .catch((error) => {
            console.error("Criar Orcamento Erro:", error);
            toast.error("Erro ao criar orçamento😢. Tente novamente.");
          });
      }
    }

    toast.success("Orçamento Criado! 🎉");
    enviarEmailParaProdutor();

    if (buscarMaterialBriefing) {
      buscarMaterialBriefing();
    }

    if (admin) {
      window.location.reload();
    }
    setAbrirModal(false);
  }

  const buscarPropostas = () => {
    api
      .get(`/propostas/${admin ? campanha.id : id}`)
      .then((response) => {
        setPropostas(response.data.data.propostas);
      })
      .catch(() => {
        toast.error("Erro ao buscar proposta");
      });
  };

  const enviarEmailParaProdutor = () => {
    const bodyEmail = {
      fornecedor_nome: fornecedor.fornecedor_nome,
      campanha: campanha,
      briefing: element,
    };

    api
      .post(
        "/email/novo-orcamento",
        { data: bodyEmail },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {})
      .catch((error) => {
        console.error(
          "Enviar Email para Produtor na criação de orçamento Erro:",
          error
        );
        toast.error("Erro ao enviar email para produtor😢");
      });
  };

  const adicionarImagem = (idDistribuicao, value) => {
    const formData = new FormData();
    value.forEach((foto, index) => {
      formData.append(`fotos[${index}]`, foto);
    });

    api
      .post(
        `/proposta-material-distribuicao/${idDistribuicao}/nf-fornecedor`,
        formData
      )
      .then(() => {
        toast.success("Imagem Adicionada");
        buscarArquivosPropostas();
        buscarPropostaMateriais();
      })
      .catch((error) => {
        console.error("Adicionar Imagem na proposta fornecedor Erro:", error);
        toast.error("Erro ao criar orçamento😢. Tente novamente.");
      });
  };

  const removerImagem = (imagem) => {
    api
      .patch(`/proposta-material-distribuicao/${imagem.id}/nf-fornecedor`, {
        dados: "",
      })
      .then(() => {
        toast.success("Imagem Removida");
        buscarArquivosPropostas();
        buscarPropostaMateriais();
      })
      .catch(() => {
        toast.error("Erro ao buscar dados");
      });
  };

  useEffect(() => {
    if (fotosSelecionadas.length > 0) {
      adicionarImagem();
    }
  }, [fotosSelecionadas]);

  const buscarPropostaMateriais = async () => {
    setPropostaMateriaisLoaded(false);
    try {
      let materiais = [];
      for (const proposta of propostas) {
        const response = await api.get(`/proposta-materiais/${proposta.id}`);
        materiais = [...materiais, ...response.data.data.materiais];
      }
      setPropostaMateriais(materiais);
      setPropostaMateriaisLoaded(true);
    } catch (error) {
      toast.error("Erro ao buscar materiais da proposta.");
    }
  };

  const buscarArquivosPropostas = async () => {
    setPropostaMateriaisLoaded(false);

    if (!admin) {
      try {
        let materiais = [];
        for (const proposta of filterOrcamentos) {
          const response = await api.get(
            `/proposta-materiais/orcamento/${proposta.id}`
          );

          if (response.data.data.materiais.length > 0) {
            const arquivos = await api.get(
              `/proposta-material-distribuicao/${response.data.data.materiais[0].id}`
            );
            setArquivoImpressao(
              response.data.data.materiais[0].arquivo_impressao
            );
            materiais = [...materiais, ...arquivos.data.data.distribuicoes];

            setArrayMaterialDistribuicoes(materiais);
          }
        }
      } catch (error) {
        toast.error("Erro ao buscar materiais da proposta.");
      }
    }
  };

  function editarOrçamento() {
    const data = getValues();

    for (let i = 0; i < element.quantidades.length; i++) {
      const objeto = {
        material_briefing_quantidades_id: element.quantidades[i].id,
        valor_unidade:
          data[`valor_unitario_${element.quantidades[i].quantidade}`],
        valor_total: data[`valor_total_${element.quantidades[i].quantidade}`],
        observacoes: data.observacao,
        prazo_producao: data[`prazo_${element.quantidades[i].quantidade}`],
        ipi: data.ipi,
        icms: dadosFornecedor.icms,
        icms_st: data.icms_st,
        categoria: data.categoria,
        estado: "1",
        fornecedor_id: admin ? fornecedor.id : fornecedor.fornecedor_id,
        campanha_material_briefing_id: element.id,
        criador_id: 1,
        data_entrega: data[`prazo_${element.quantidades[i].quantidade}`],
        status: "pendente",
        validade_orcamento: data.prazo,
      };

      const orcamento = filterOrcamentos.find(
        (elem) =>
          elem.material_briefing_quantidades_id == element.quantidades[i].id
      );

      api
        .patch(
          `/orcamento-materiais-briefing/${Number(orcamento.id)}`,
          {
            valor_unidade:
              data[`valor_unitario_${element.quantidades[i].quantidade}`],
            valor_total:
              data[`valor_total_${element.quantidades[i].quantidade}`],
            data_entrega: data[`prazo_${element.quantidades[i].quantidade}`],
            validade_orcamento: data.prazo,
            ipi: data.ipi,
            icms_st: data.icms_st,
            status: data[`participar_${element.quantidades[i].quantidade}`]
              ? "Não Participa"
              : "pendente",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          toast.success("Orçamento Editado! 🎉");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Criar Orcamento Erro:", error);
          toast.error("Erro ao criar orçamento😢. Tente novamente.");
        });
    }
  }

  useEffect(() => {
    if (admin) {
      return;
    }
    buscarPropostas();
    buscarArquivosPropostas();
  }, []);

  useEffect(() => {
    if (admin) {
      return;
    }
    buscarPropostaMateriais();
  }, [propostas]);

  return element ? (
    <FormPropostaFornecedorPageStyle>
      <div className="div-top">
        <div className="justify">
          <div className="flex">
            <h2>
              Material {index + 1}/{length}:
            </h2>
            <h3 className="nome">
              {element.id}
              {element.nome} {element.subnome ? element.subnome : ""}
            </h3>
          </div>
          <div className="flex">
            <h4 className="titulo-prazo">
              Prazo final de envio das propostas:{" "}
            </h4>
            <h5 className="prazo">{element.prazo_propostas}</h5>
          </div>
        </div>
      </div>
      <div className="body">
        <div className="flex justify">
          <div className="div-especificacao">
            <h3 className="titulo-especificacao">Produtor:</h3>
            <h4 className="especificacao margin-button">
              {element.produtor_nome +
                " " +
                (element.produtor_sobrenome ? element.produtor_sobrenome : "")}
            </h4>
            <h3 className="titulo-especificacao">Especificação:</h3>
            <h4 className="especificacao">
              {element.categoria && element.categoria === "Brindes"
                ? ` Descrição: ${element.descricao} | Público Alvo: ${
                    element.publico_alvo
                  } | Item de Marca: ${element.item_de_marca ? "Sim" : "Não"} `
                : element.categoria === "Material Impresso"
                ? `Descrição: ${element.descricao} | Quantidade de páginas: ${element.quantidade_de_paginas} | Formato aberto: ${element.formato_aberto}cm | Formato fechado: ${element.formato_fechado}cm | Tipo de Papel: ${element.substrato} | Gramatura: ${element.gramatura} | Cores: ${element.cores} | Acabamentos: ${element.acabamentos} `
                : element.categoria == "Ponto de Venda"
                ? `Descrição: ${element.descricao} | Tamanho Final: ${
                    element.tamanho
                  } | Substrato: ${element.substrato} | Espessura: ${
                    element.espessura
                  } | Cores: ${element.cores} | Acabamentos: ${
                    element.acabamentos
                  } | Necessário Positivação: ${
                    element.positivacao ? "Sim" : "Não"
                  } `
                : element.categoria === "Comunicação Visual"
                ? `Descrição: ${element.descricao} | Tamanho Final: ${
                    element.tamanho
                  } | Formato aberto: ${
                    element.formato_aberto
                  }cm | Substrato: ${element.substrato} | Gramatura: ${
                    element.gramatura
                  } | Cores: ${element.cores} | Acabamentos: ${
                    element.acabamentos
                  } | Necessário Positivação: ${
                    element.positivacao ? "Sim" : "Não"
                  }`
                : ""}
            </h4>
            <div className="flex">
              <h4 className="font-size weight">
                Arte:{" "}
                {arquivoImpressao ? (
                  <a className="arquivo-impressao" href={arquivoImpressao}>
                    Arquivo de Impressão
                  </a>
                ) : (
                  "Esperando Cliente Enviar"
                )}
              </h4>
            </div>
          </div>
          <div className="right">
            {element.tipo === "Serviço" ? (
              <></>
            ) : (
              <div>
                <h5>NCM</h5>
                <span>{element.ncm_codigo}</span>
              </div>
            )}
            <div>
              <h5>Tipo de produto</h5>
              <span>{element.item_nome}</span>
            </div>
            <div>
              <h5>Imprimir Pedido de Orçamento</h5>
              <span
                className="arquivo-impressao"
                onClick={() => setMaterialPedidoOrcamento(true)}
              >
                pedido-orçamento{element.id}
              </span>
            </div>
          </div>
        </div>
        <FormStyle onSubmit={handleSubmit(criarOrcamento)}>
          <div className="flex">
            <div className={`div-categoria ${admin ? "width" : ""}`}>
              <h4>Categoria</h4>
              <input
                disabled={dataAtual <= prazoMaximoDeProposta ? false : true}
                {...register(`categoria`)}
                value={dadosFornecedor && dadosFornecedor.categoria}
              />
            </div>
            <div className={`div-estado ${admin ? "width" : ""}`}>
              <h4>Estado de origem</h4>
              <input
                disabled={dataAtual <= prazoMaximoDeProposta ? false : true}
                value={dadosFornecedor && dadosFornecedor.estado}
              ></input>
            </div>
            <div className={`div-estado ${admin ? "width" : ""}`}>
              <h4>Regime Empresa</h4>
              <input
                disabled={dataAtual <= prazoMaximoDeProposta ? false : true}
                value={dadosFornecedor && dadosFornecedor.regime_empresa}
              ></input>
            </div>
            {element.tipo === "Serviço" ? (
              <div className="div-icms">
                <h4>Imposto</h4>
                <input value="ISS" />
              </div>
            ) : (
              <>
                {" "}
                <div className={`div-icms ${admin ? "width" : ""}`}>
                  <h4>ICMS</h4>
                  <input
                    disabled={dataAtual <= prazoMaximoDeProposta ? false : true}
                    {...register(`icms`)}
                    type="number"
                    placeholder="00%"
                    min={0.0}
                    step={0.0001}
                    value={dadosFornecedor && dadosFornecedor.icms}
                  />
                </div>
                <div className={`div-ipi ${admin ? "width" : ""}`}>
                  <h4>IPI</h4>
                  <input
                    disabled={dataAtual <= prazoMaximoDeProposta ? false : true}
                    {...register(`ipi`)}
                    type="number"
                    placeholder="00%"
                    step={0.0001}
                    min={0.0}
                  />
                </div>
                <div className={`div-icms-st ${admin ? "width" : ""}`}>
                  <h4>ICMS-ST/MVA</h4>
                  <input
                    disabled={dataAtual <= prazoMaximoDeProposta ? false : true}
                    {...register(`icms_st`)}
                    type="number"
                    placeholder="00%"
                    step={0.0001}
                    min={0.0}
                  />
                </div>
              </>
            )}
          </div>
          <TabelaStyle className="tabela">
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    {colunas.map((elem) => {
                      if (
                        elem == "Não Participar" &&
                        filterOrcamentos?.length > 0 &&
                        !admin
                      ) {
                        return <></>;
                      }
                      return <th>{elem}</th>;
                    })}
                    {filterOrcamentos?.length > 0 && <th>Status</th>}
                  </tr>
                </thead>
                <tbody>
                  {""}
                  {element.quantidades.map((quantidade) => {
                    return (
                      <tr>
                        {
                          <>
                            <td className="quantidade h5">
                              {quantidade.quantidade} unidades
                            </td>
                            {filterOrcamentos?.length > 0 &&
                            filterOrcamentos?.filter(
                              (elem) => elem.quantidade == quantidade.quantidade
                            ).length > 0 &&
                            !admin ? (
                              <></>
                            ) : (
                              <td className="participar">
                                <input
                                  disabled={
                                    dataAtual <= prazoMaximoDeProposta
                                      ? false
                                      : true
                                  }
                                  {...register(
                                    `participar_${quantidade.quantidade}`
                                  )}
                                  defaultChecked={
                                    filterOrcamentos?.length > 0 &&
                                    filterOrcamentos?.filter(
                                      (elem) =>
                                        elem.quantidade == quantidade.quantidade
                                    )[0]?.status == "Não Participa"
                                      ? true
                                      : false
                                  }
                                  className="check"
                                  type="checkbox"
                                />
                              </td>
                            )}

                            <td>
                              {filterOrcamentos?.length > 0 &&
                              filterOrcamentos?.filter(
                                (elem) =>
                                  elem.quantidade == quantidade.quantidade
                              ).length > 0 ? (
                                <input
                                  defaultValue={
                                    filterOrcamentos?.length > 0 &&
                                    filterOrcamentos?.filter(
                                      (elem) =>
                                        elem.quantidade == quantidade.quantidade
                                    )[0].valor_unidade
                                  }
                                  disabled={admin ? false : true}
                                  {...register(
                                    `valor_unitario_${quantidade.quantidade}`,
                                    {
                                      onChange: (e) => {
                                        setValue(
                                          `valor_total_${quantidade.quantidade}`,
                                          Number(e.target.value) *
                                            quantidade.quantidade
                                        );
                                      },
                                    }
                                  )}
                                  id="numeroFloat"
                                  placeholder="R$ 00,00"
                                />
                              ) : (
                                <input
                                  disabled={
                                    dataAtual <= prazoMaximoDeProposta
                                      ? false
                                      : true
                                  }
                                  {...register(
                                    `valor_unitario_${quantidade.quantidade}`,
                                    {
                                      onChange: (e) => {
                                        setValue(
                                          `valor_total_${quantidade.quantidade}`,
                                          Number(e.target.value) *
                                            quantidade.quantidade
                                        );
                                      },
                                    }
                                  )}
                                  id="numeroFloat"
                                  placeholder="R$ 00,00"
                                  step={0.0001}
                                  type="number"
                                />
                              )}
                            </td>
                            <td className="valor-total">
                              {filterOrcamentos?.length > 0 &&
                              filterOrcamentos?.filter(
                                (elem) =>
                                  elem.quantidade == quantidade.quantidade
                              ).length > 0 ? (
                                <input
                                  disabled={true}
                                  {...register(
                                    `valor_total_${quantidade.quantidade}`
                                  )}
                                  value={
                                    filterOrcamentos?.length > 0 &&
                                    filterOrcamentos
                                      .filter(
                                        (elem) =>
                                          elem.quantidade ===
                                          quantidade.quantidade
                                      )
                                      .map((cotacao) =>
                                        (
                                          cotacao.valor_unidade *
                                          quantidade.quantidade
                                        ).toFixed(4)
                                      )
                                  }
                                  id="numeroFloat"
                                  placeholder="R$ 00,00"
                                />
                              ) : (
                                <input
                                  {...register(
                                    `valor_total_${quantidade.quantidade}`
                                  )}
                                  id="numeroFloat"
                                  placeholder="R$ 00,00"
                                  step={0.0001}
                                />
                              )}
                            </td>
                            <td className="prazo">
                              {filterOrcamentos?.length > 0 &&
                              filterOrcamentos?.filter(
                                (elem) =>
                                  elem.quantidade == quantidade.quantidade
                              ).length > 0 ? (
                                <input
                                  disabled={admin ? false : true}
                                  {...register(
                                    `prazo_${quantidade.quantidade}`
                                  )}
                                  type="number"
                                  id="numeroFloat"
                                  placeholder="00 dias"
                                  defaultValue={
                                    filterOrcamentos?.length > 0 &&
                                    filterOrcamentos?.filter(
                                      (elem) =>
                                        elem.quantidade == quantidade.quantidade
                                    )[0].prazo_producao
                                  }
                                />
                              ) : (
                                <input
                                  disabled={
                                    dataAtual <= prazoMaximoDeProposta
                                      ? false
                                      : true
                                  }
                                  {...register(
                                    `prazo_${quantidade.quantidade}`
                                  )}
                                  type="number"
                                  id="numeroFloat"
                                  placeholder="00 dias"
                                />
                              )}
                            </td>
                            <td className="status">
                              {filterOrcamentos?.length > 0 &&
                              filterOrcamentos?.filter(
                                (elem) =>
                                  elem.quantidade == quantidade.quantidade
                              ).length > 0 ? (
                                <h4>
                                  {filterOrcamentos?.length > 0 &&
                                    filterOrcamentos?.filter(
                                      (elem) =>
                                        elem.quantidade == quantidade.quantidade
                                    )[0].status}
                                </h4>
                              ) : (
                                <></>
                              )}
                            </td>
                          </>
                        }
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </TabelaStyle>
          <div className="div-observacao">
            <h4 className={admin ? "label-observacoes" : ""}>Observação</h4>
            {filterOrcamentos?.length > 0 ? (
              <>
                {/* {
                  filterOrcamentos?.filter(
                    (elem) => elem.quantidade == element.quantidade_c
                  )[0].observacoes
                } */}
              </>
            ) : (
              <input
                disabled={dataAtual <= prazoMaximoDeProposta ? false : true}
                {...register(`observacao`)}
                placeholder="Observações"
                className="observacao"
              />
            )}
          </div>
          <div className={`flex div-final ${admin ? "twogrid" : ""}`}>
            <div className="flex div-validacao">
              <h4 className={admin ? "h4-diminuir" : ""}>
                Validade do orçamento:
              </h4>
              {filterOrcamentos?.length > 0 && !admin ? (
                <>
                  {filterOrcamentos[0].validade_orcamento
                    ? filterOrcamentos[0].validade_orcamento
                        .split("-")
                        .reverse()
                        .join("/")
                    : ""}
                </>
              ) : (
                <input
                  disabled={dataAtual <= prazoMaximoDeProposta ? false : true}
                  {...register("prazo")}
                  type="date"
                  className="input-prazo"
                  defaultValue={
                    filterOrcamentos && filterOrcamentos[0]?.validade_orcamento
                  }
                />
              )}
            </div>
            {filterOrcamentos?.length > 0 && admin && (
              <span className="editar" onClick={() => editarOrçamento()}>
                Editar
              </span>
            )}
            {filterOrcamentos?.length == 0 && (
              <span
                hidden={travarBotao}
                className="enviar"
                onClick={() => setAbrirModal(true)}
              >
                Enviar
              </span>
            )}
          </div>
          {admin ? (
            <></>
          ) : filterOrcamentos?.length > 0 ? (
            loading === true ? (
              <Loading />
            ) : (
              <div className="div-nf">
                <div className="flex">
                  <h4 className="font-weight">
                    {propostaMateriais.length ? (
                      <>
                        Pedido de compras:{" "}
                        {propostaMateriais.length > 0 &&
                          element.quantidades.map((dadosQuantidade) => {
                            const orcamento = filterOrcamentos?.find(
                              (elem) =>
                                elem.quantidade === dadosQuantidade.quantidade
                            );
                            return propostaMateriais
                              .filter(
                                (elem) => elem.orcamento_id == orcamento?.id
                              )
                              .map((elem) => (
                                <span
                                  key={elem.id} // Sempre adicionar uma `key` ao map.
                                  className="pdf"
                                  onClick={() => {
                                    setMaterial(elem);
                                    setMaterialPedidoCompras(true);
                                  }}
                                >
                                  pedido-compras{elem.id}.pdf
                                </span>
                              ));
                          })}
                      </>
                    ) : (
                      <></>
                    )}
                  </h4>
                  <div className=" flex div-nf-right">
                    <h4 className="font-weight vertical">
                      {propostaMateriais.length ? (
                        <>
                          Prazo para envio da prova{" "}
                          {propostaMateriais.length > 0 &&
                            element.quantidades.map((dadosQuantidade) => {
                              const orcamento = filterOrcamentos?.find(
                                (elem) =>
                                  elem.quantidade === dadosQuantidade.quantidade
                              );
                              return propostaMateriais
                                .filter(
                                  (elem) => elem.orcamento_id == orcamento?.id
                                )
                                .map((elem) => <span>{elem.data_prova}</span>);
                            })}
                        </>
                      ) : (
                        <></>
                      )}
                    </h4>
                    <h4 className="font-weight">
                      Tipo: <span> Prova digital</span>
                    </h4>
                  </div>
                </div>
                <div className="flex">
                  <div className="div-nota">
                    <h4 className="font-weight">Nota fiscal</h4>
                    <div className="input-container">
                      <div className="column">
                        {arrayMaterialDistribuicoes.length ? (
                          arrayMaterialDistribuicoes.map((elem) => {
                            if (elem.nf_fornecedor) {
                              return (
                                <div>
                                  <span
                                    className="close"
                                    onClick={() => removerImagem(elem)}
                                  >
                                    X
                                  </span>
                                  <a href={elem.nf_fornecedor}>
                                    {`Nota Fiscal de ${elem.quantidade} Unidades para ${elem.destino}`}
                                  </a>
                                </div>
                              );
                            } else {
                              return (
                                <>
                                  <h3>
                                    {elem.quantidade} Unidades para{" "}
                                    {elem.destino}
                                  </h3>
                                  <div>
                                    <input
                                      {...register("imagem")}
                                      type="file"
                                      id={`file${elem.id}`}
                                      hidden={true}
                                      accept="image/*"
                                      onChange={(e) => {
                                        adicionarImagem(elem.id, [
                                          ...e.target.files,
                                        ]);
                                      }}
                                    />
                                    <label htmlFor={`file${elem.id}`}>
                                      Selecionar arquivo
                                    </label>
                                    <label
                                      htmlFor={`file${elem.id}`}
                                      className="pesquisar"
                                    >
                                      Pesquisar
                                    </label>
                                  </div>
                                </>
                              );
                            }
                          })
                        ) : (
                          <span>Aguardando Distribuição</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="column flex-end">
                    {/* <span>
                      {`
                    Resposta enviada em ${" "}
                    ${
                      filterOrcamentos?.length > 0 &&
                      filterOrcamentos?.filter(
                        (elem) => elem.quantidade == element.quantidade_a
                      )[0].data_criacao
                    }`}
                    </span> */}
                    <span>
                      {filterOrcamentos?.filter(
                        (elem) =>
                          elem.status === "proposta aprovada" ||
                          elem.status === "aprovado"
                      ).length > 0 &&
                        `
                    Aprovação em ${" "}
                    ${
                      filterOrcamentos?.length > 0 &&
                      filterOrcamentos?.find(
                        (elem) =>
                          elem.status == "proposta aprovada" ||
                          elem.status === "aprovado"
                      ).data_atualizacao
                    }`}
                    </span>
                    <span></span>
                  </div>
                </div>
              </div>
            )
          ) : (
            <></>
          )}
          {abrirModal && (
            <ConfirmacaoDeOrcamento
              state={setAbrirModal}
              verificarData={verificarData}
              handleSubmit={handleSubmit}
              criarOrcamento={criarOrcamento}
              setAbrirModal={setAbrirModal}
            />
          )}
          {materialPedidoCompras && (
            <GerarPedidoComprasModal
              setModalOpened={setMaterialPedidoCompras}
              campanha={campanha}
              material={material}
              fornecedor={true}
            />
          )}
          {materialPedidoOrcamento && (
            <GerarPedidoOrcamentoModal
              setModalOpened={setMaterialPedidoOrcamento}
              campanha={campanha}
              material={element}
              fornecedor={dadosFornecedor}
            />
          )}
        </FormStyle>
      </div>
    </FormPropostaFornecedorPageStyle>
  ) : (
    <Loading />
  );
};
