import { styled } from "styled-components";

export const DashBoardContainer = styled.div`
  width: calc(100% - 260px);
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 55px 55px 0px 55px;
  background: var(--base01);
  overflow: auto;

  .width90 {
    width: 90%;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .space-between {
    width: 100%;
    display: flex;
    margin: 60px auto 0px;
    justify-content: space-between;
  }

  .grafico-linha {
    width: 100%;
    height: 25%;
  }

  .graficos {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    .graficoBolinha {
      width: 30%;
      margin-top: 30px;
      background-color: #ffffff;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;

      li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        span {
          min-width: 16px;
          min-height: 16px;
          margin-right: 6px;
          border-radius: 50%;
          background-color: #dcdcdc;
        }
      }
      li::marker {
        color: #ededed;
        font-size: 1.5em;
        line-height: 1;
      }
    }

    .teste {
      width: 100%;
    }

    canvas {
      width: 60% !important;
      height: auto !important;
      aspect-ratio: 1 / 1 !important;
    }
  }

  .card-Dados {
    width: 30%;
    display: flex;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    height: 150px;

    .linha-vermelha {
      width: 8px;
      height: 77px;
      background-color: #640000;
      border-radius: 20px;
      margin-right: 20px;
    }

    h3 {
      font-size: 16px;
    }

    .porcentagem {
      margin-top: 28px;
      font-weight: 600;
      margin-left: -32px;
      display: flex;

      svg {
        margin-right: 8px;
      }

      span {
        margin-left: 5px;
      }
    }

    .positivo {
      color: #40ad48;
    }

    .negativo {
      color: #9f0010;
    }

    h2 {
      color: rgb(17, 17, 17);
      font-size: 50px;
      font-weight: 700;
      margin-top: 30px;
    }
  }

  header {
    margin-top: 40px;
    display: flex;
    align-items: center;

    h1 {
      font-size: 45px;
      font-weight: 600;
      color: #640000;
      margin-left: 20px;
    }
  }

  .filtros {
    margin-top: 40px;

    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #640000;
    }

    .filtro {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 80px;
      gap: 10px;
      margin-top: 40px;

      select {
        width: 200px;
        border-radius: 6px;
        color: #ffffff;
        background-color: #640000 !important;
        padding: 5px 0px;
        text-align: center;
        border-radius: 4px;
        border: none;
      }

      .custom-select {
        position: relative;
        display: inline-block;
        width: 200px;
        background-color: #640000;
        border-radius: 8px;
      }

      .custom-select select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
        padding: 8px 0px 8px 0px;
        border: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
      }

      .custom-select::after {
        content: "";
        font-size: 12px;
        position: absolute;
        right: auto;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: #666;
      }

      option {
        color: rgb(255, 255, 255);
      }
    }
  }

  .grafico-vazio {
    display: flex;
    width: 30%;
    align-items: center;

    li {
      min-width: 110px;
    }

    li::marker {
      color: #ededed; /* Muda a cor da bullet */
      font-size: 40px;
    }
  }

  .circuloBranco {
    width: 100%;
  }

  .media-mes {
    width: 435px !important;

    select {
      margin-left: 20px;
    }
  }

  .column {
    span {
      font-size: 14px;
      margin-left: 3px;
    }
  }

  .top-div-grafico {
    display: flex;
    justify-content: space-between;

    select {
      border: none;
      border-radius: 5px;
      background-color: var(--principal);
      color: var(--base);
      padding: 0px 7px;
      font-weight: 500;
    }

    option {
      color: var(--base);
    }
  }

  .bold {
    font-weight: 600;
    font-size: 36px;
    margin-left: 10px;
  }

  .falante {
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .span-little {
    font-size: 12px;
    margin-left: 3px;
    width: 140px;
  }

  .titulo-div-grafico {
    display: flex;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
`;

export const BoxRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 55px 0px 0px 55px;
`;

export const Main = styled.div`
  width: 87%;
  height: 85%;

  .flex {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  svg {
    width: 45px;
    height: 45px;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    margin-left: 10px;
  }

  .relatorio {
    display: flex;
    gap: 20px;
    justify-content: space-around;
  }
`;

export const Jobs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;

  .graficos-campanha {
    width: 100%;
    min-width: 270px;
    position: relative;
    padding: 50px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    height: max-content;
  }

  .quantidade-campanha {
    width: 400px;
    height: max-content;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-right: 30px;
    canvas {
      width: 358px !important;
      height: 179px !important;
    }
  }

  .valor-medio {
    display: flex;
    justify-content: center;
    width: 300px;
    font-size: 40px;
    font-weight: 700;
    margin-top: 20px;
    color: var(--principal);
    align-items: center;
  }

  .status-itens {
    width: 280px;
    height: max-content;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-right: 30px;
    position: relative;
  }

  .status-itens-fornecedor {
    width: 400px;
    height: max-content;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-right: 30px;
    position: relative;
  }

  .valor-campanha-grafico {
    width: 320px;
    height: max-content;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-right: 30px;
    position: relative;
    height: 320px;

    > div {
      display: flex;
      align-content: center;
      justify-content: center;
    }

    svg {
      width: 115px;
      height: 164px;

      path,
      polygon,
      rect,
      circle,
      ellipse {
        fill: #640000;
      }
    }
  }

  .select-clientes {
    position: absolute;
    top: 0px;
    font-size: 16px;
    font-weight: 700;
    color: var(--principal);
    right: 0;
    padding: 2px 8px;
    border-radius: 8px;
  }

  .titulo-grafico {
    position: absolute;
    top: 8px;
    font-size: 25px;
    font-weight: 700;
    color: var(--principal);
  }

  .urgent-job {
    width: 23%;
    min-width: 200px;
    height: 50px;
  }

  .span-center {
    position: absolute;
    top: 95px;
    right: 128px;
    font-size: 16px;
    font-weight: 600;
    width: 30px;
    display: flex;
    text-align: center;
  }

  .span-center-fornecedor {
    position: absolute;
    top: 153px;
    right: 220px;
    font-size: 40px;
    font-weight: 600;
    width: 30px;
    display: flex;
    text-align: center;
    line-height: 0.8;
  }

  .span-valor-total {
    position: absolute;
    top: 175px;
    right: 116px;
    font-size: 20px;
    font-weight: 600;
    width: 36%;
    display: flex;
    text-align: center;
    line-height: 1;
  }

  .material {
    width: 31%;
    min-width: 270px;
    height: 50px;
    /* background-color: black; */
  }

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    > p {
      font-size: 0.9rem;
      font-weight: 700;
      line-height: 1.5;
    }
  }

  .divScroll {
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--grayA5);
    border-radius: 7px;
  }

  .cards {
    width: 400vh;
    display: flex;
    gap: 20px;

    .cardItens {
      margin-top: 20px;
      padding: 20px;
      background-color: var(--grayEF);
      border-radius: 5px;

      .urgenciaAlta {
        background-color: var(--urgenciaAlta);
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 1px;
        min-width: 75%;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 5px;
        p {
          font-size: 0.8rem;
          color: var(--base);
        }
      }

      .urgenciaBaixa {
        background-color: var(--urgenciaBaixa);
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 1px;
        min-width: 75%;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 5px;
        p {
          font-size: 0.8rem;
          color: var(--base);
        }
      }

      .urgenciaMedia {
        background-color: var(--urgenciaMedia);
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 1px;
        min-width: 75%;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 5px;
        p {
          font-size: 0.8rem;
          color: var(--base);
        }
      }
      svg {
        margin-left: 5px;
      }

      > p {
        font-size: 0.7rem;
        font-weight: 500;
      }
      h2 {
        font-size: 0.9rem;
        font-weight: 600;
      }

      .data {
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--grayA5);
      }

      .cardHeader {
        display: flex;
        justify-content: space-between;

        P {
          font-size: 0.8rem;
          font-weight: 500;
        }

        a {
          color: #609ae5;
          font-size: 0.7rem;
          display: flex;
          align-items: center;
        }
      }
    }

    .item {
      width: 250px;
      padding: 10px;
      height: 500px;
      margin-top: 35px;
      margin-bottom: 20px;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--grayA5);
      box-shadow: 0px 3px 6px #00000012;
      overflow: auto;
      > p {
        text-align: start;
        font-size: 0.9rem;
        margin-top: 10px;
        font-weight: 600;
        width: 100%;
        line-height: 1.5;
      }
    }
  }
`;

export const ContainerTexto = styled.div`
  display: "flex";
  flex-direction: row;
`;

export const TituloSpan = styled.span`
  font-weight: bold;
  font-size: 12px;
`;
