import { BoxRight, DashBoardContainer, Jobs, Main } from "./styles";
import dashboard from "../../assets/menu-dash.svg";
import { ReactSVG } from "react-svg";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { useEffect } from "react";
import api from "../../services/api";
import { useState } from "react";
import falante from "../../assets/campanha.svg";
import relogio from "../../assets/urgent-job.svg";
import imposto from "../../assets/imposto-menu.svg";
import Analise from "../../assets/analise.png";
import Voltar from "../../assets/voltar.png";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";
import { useAuth } from "../../providers/Auth";
import { toast } from "react-toastify";
import Loading from "../Loading";
import { moneyMask } from "../../utils/masks";
import { RelatorioContasPagar } from "../RelatorioContasPagar";
import { RelatorioContasReceber } from "../RelatorioContasReceber";
import { ModalPropostasNfsPendentes } from "../Modal/PropostasNfsPendentes";
import Negativo from "../../assets/negativo.svg";
import Positivo from "../../assets/positivo.svg";

ChartJS.register(
  LineElement,
  BarElement,
  ArcElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { user, token } = useAuth();
  const [campanhas, setCampanhas] = useState([]);
  const [quantidadeCampanhas, setQuantidadeCampanhas] = useState(0);
  const [valorProduzidoPorMeses, setValorProduzidoPorMeses] = useState([]);
  const [briefings, setBriefings] = useState([]);
  const [valorTotalPropostas, setValorTotalPropostas] = useState(false);
  const [tempoMedioCampanha, setTempoMedioCampanha] = useState("");
  const [briefingsAnuais, setBriefingsAnuais] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [orcamentos, setOrcamentos] = useState([]);
  const [orcamentoAnuais, setOrcamentoAnuais] = useState([]);
  const [abrirPropostasNfsPendentes, setAbrirPropostasNfsPendentes] =
    useState(false);
  const [dadosDashboard, setDadosDashboard] = useState({ loading: true });
  const [filtros, setFiltros] = useState({
    ano: new Date().getFullYear(),
    mes: new Date().getMonth() + 1,
  });
  const [clienteIdSelecionado, setClienteIdSelecionado] = useState(
    user.clientes
  );

  const [clientesIdsResponsaveis, setClientesIdsResponsaveis] = useState([]);

  const anos = [
    { key: 0, value: null, label: "Selecionar" },
    { key: 1, value: 2023, label: "2023" },
    { key: 2, value: 2024, label: "2024" },
    { key: 3, value: 2025, label: "2025" }
  ]

  const cores = [
    "#FFBE00",
    "#5A4300",
    "#130F03",
    "#9F0010",
    "#C38E94",
    "#EB0018",
    "#EF5713",
    "#903C16",
    "#FF8D5A",
    "#AA7F00",
  ];

  function buscarBriefingCampanha(cliente_id) {
    api.get("/campanha-material-briefing", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const data = response.data.data.materiais;
        if (cliente_id) {
          const filtro = data.filter(
            (element) =>
              element.criador_id == cliente_id && Number(element.status) != 0
          );
          setBriefings(filtro);
        } else {
          setBriefings(data);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const listarPropostasAprovadas = (cliente_id) => {
    api
      .get(`/proposta-materiais/cliente/${cliente_id}`)
      .then((response) => {
        const desconstruindoArrayDeArrays =
          response.data.data.materiais.flatMap((element) => element.materiais);

        const preço = desconstruindoArrayDeArrays.reduce((element, acc) => {
          if (acc.origem === "Briefing") {
            return Number(element) + Number(acc.valor_final);
          } else {
            return Number(element) + Number(acc.valor_total);
          }
        }, 0);
        setValorTotalPropostas(preço);
      })
      .catch((error) => {
        toast.error("Erro ao buscar histórico de provas.");
      });
  };

  const buscarPrazoCampanhas = (cliente_id) => {
    api
      .get(`/historico-campanha/campanhas/finalizadas/${cliente_id}`)
      .then((response) => {
        setTempoMedioCampanha(response.data.data.prazo);
      })
      .catch((error) => {
        toast.error("Erro ao buscar histórico de provas.");
      });
  };

  const listarClientes = () => {
    api
      .get(`/clientes`)
      .then((response) => {
        setClientes(response.data.data.clientes);
      })
      .catch((error) => {
        toast.error("Erro ao buscar histórico de provas.");
      });
  };

  function buscarCliente() {
    if (user.permissoes.includes("admin") && !clienteSelecionado) {
      buscarBriefingCampanha();
      listarPropostasAprovadas();
      buscarPrazoCampanhas();
      return buscarCampanhas();
    } else if (user.permissoes.includes("admin") && clienteSelecionado) {
      buscarBriefingCampanha(clienteSelecionado);
      listarPropostasAprovadas(clienteSelecionado);
      buscarPrazoCampanhas(clienteSelecionado);
      return buscarCampanhas(clienteSelecionado);
    } else if (user.permissoes.includes("compras")) {
      buscarBriefingCampanha();
      listarPropostasAprovadas();
      buscarPrazoCampanhas();
      return buscarCampanhas();
    }

    api.get(`/usuario-cliente/${user.id}`)
      .then((response) => {
        buscarCampanhas(response.data.data.usuario.cliente_id);
        buscarBriefingCampanha(response.data.data.usuario.cliente_id);
        listarPropostasAprovadas(response.data.data.usuario.cliente_id);
        buscarPrazoCampanhas(response.data.data.usuario.cliente_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCampanhas(cliente_id) {
    api.get("/campanhas")
      .then((response) => {
        const data = response.data.data.campanhas;
        if (cliente_id) {
          const filtro = data.filter(
            (element) =>
              element.cliente_id == cliente_id && Number(element.status) != 0
          );
          setCampanhas(filtro);
        } else {
          setCampanhas(data);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const listarTotalCampanhaPorAno = (anoSelecionado) => {
    const months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    campanhas.map((campanha) => {
      const tirarT = campanha.data_criacao.split(" ")[0];
      const mes = tirarT.split("/")[1];
      const ano = tirarT.split("/")[2];

      if (anoSelecionado && ano !== String(anoSelecionado)) {
        return;
      }

      months[Number(mes) - 1]++;
    });

    setQuantidadeCampanhas(months);
  };

  const buscarValoresProduzidos = (anoSelecionado, cliente) => {
    api.get(
      `/relatorios/media_por_mes?ano=${anoSelecionado}${user.permissoes.includes("admin") && !cliente
        ? ""
        : cliente
          ? "&cliente_id=" + [cliente]
          : "&cliente_id=" + user.clientes
      }`
    )
      .then((response) => {
        const months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        response.data.data.materiais.map((campanha) => {
          const tirarT = campanha.data_criacao.split(" ")[0];
          const mes = tirarT.split("/")[1];

          months[Number(mes) - 1] += Number(campanha.valor_de_venda);
          setValorProduzidoPorMeses(months);
        });

        if (response.data.data.materiais.length === 0) {
          setValorProduzidoPorMeses([]);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  const verificarBriefingsPorAno = (anoSelecionado) => {
    const briefingsSelecionados = [];

    for (let i = 0; i < briefings.length; i++) {
      const data = moment(briefings[i].data_criacao, "DD/MM/YYYY");
      const ano = data.year();
      if (anoSelecionado && ano !== Number(anoSelecionado)) {
        continue;
      }

      briefingsSelecionados.push(briefings[i]);
    }

    setBriefingsAnuais(briefingsSelecionados);
  };

  const verificarOrcamentoPorAno = (anoSelecionado) => {
    const orcamentoSelecionados = [];

    for (let i = 0; i < orcamentos.length; i++) {
      const data = moment(orcamentos[i].data_criacao, "DD/MM/YYYY");
      const ano = data.year();
      if (anoSelecionado && ano !== Number(anoSelecionado)) {
        continue;
      }

      orcamentoSelecionados.push(orcamentos[i]);
    }

    setOrcamentoAnuais(orcamentoSelecionados);
  };

  function buscarOrcamentoBriefing(id) {
    api
      .get(`/orcamento-materiais-briefing/fornecedor/${id}`)
      .then((response) => {
        const data = response.data.data.orcamentos;
        setOrcamentos(data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarFornecedor() {
    api
      .get(`/usuario-fornecedor/${user.id}`)
      .then((response) => {
        buscarOrcamentoBriefing(response.data.data.usuario.fornecedor_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function clientesResponsaveis() {
    api
      .get(`/clientes/cliente/responsaveis`)
      .then((response) => {
        setClientesIdsResponsaveis(response.data.data.clientes);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarDadosDashboard() {
    if (filtros.ano || filtros.mes) {
      setDadosDashboard({ loading: true, ...dadosDashboard });

      api.get("/relatorios/dados_dashboard", {
        params: {
          ano: filtros.ano,
          mes: filtros.mes,
          cliente_id: clienteIdSelecionado,
        },
      })
        .then((response) => {
          setDadosDashboard({ loading: false, ...response.data.data.dados });
        })
        .catch((error) => {
          toast.error("Erro ao buscar dados do dashboard.");
        });
    }
    else {
      console.log('sem filtros')
    }
  }

  useEffect(() => {
    if (user.permissoes.includes("cliente")) {
      buscarCliente();
    }

    if (
      user.permissoes.includes("fornecedor") &&
      !user.permissoes.includes("admin")
    ) {
      buscarFornecedor();
    }

    if (user.permissoes.includes("admin")) {
      listarClientes();
    }

    if (
      (user.permissoes.includes("cliente") &&
        !user.permissoes.includes("fornecedor")) ||
      user.permissoes.includes("admin")
    ) {
      buscarValoresProduzidos(new Date().getFullYear(), clienteSelecionado);
      buscarDadosDashboard();
    }
  }, [clienteSelecionado]);

  useEffect(() => {
    listarTotalCampanhaPorAno(new Date().getFullYear());
  }, [campanhas]);

  useEffect(() => {
    verificarBriefingsPorAno(new Date().getFullYear());
  }, [briefings]);

  useEffect(() => {
    verificarOrcamentoPorAno(new Date().getFullYear());
  }, [orcamentos]);

  useEffect(() => {
    if (user && user.perfil !== "fornecedor") {
      buscarDadosDashboard();
    }
  }, [filtros, clienteIdSelecionado]);

  useEffect(() => {
    user.perfil !== "cliente" && clientesResponsaveis();

    if (
      (user.permissoes.includes("cliente") &&
        !user.permissoes.includes("fornecedor")) ||
      user.permissoes.includes("admin")
    ) {
      buscarValoresProduzidos(new Date().getFullYear());
    }
  }, []);

  return (
    <DashBoardContainer>
      {user && user.perfil !== "fornecedor" ? (
        <div className="width90">
          <header>
            <img src={Analise} className="analise" />
            <h1>Análise</h1>
          </header>
          {dadosDashboard.loading ? (
            <Loading />
          ) : (
            <>
              <div className="filtros">
                <h2>Campanhas | Outsourcing</h2>
                <div className="flex">
                  <div className="filtro">
                    <h3>Filtrar por ano</h3>
                    <select
                      value={filtros.ano}
                      onChange={(e) =>
                        setFiltros({ ...filtros, ano: e.target.value })
                      }
                    >
                      {anos.map((ano) => (<option value={ano.value}>{ano.label}</option>))}
                    </select>
                  </div>
                  <div className="filtro">
                    <h3>Filtrar por mês</h3>
                    <select
                      value={filtros.mes}
                      onChange={(e) =>
                        setFiltros({ ...filtros, mes: e.target.value })
                      }
                    >
                      <option value="00">Selecionar</option>
                      <option value="01">Janeiro</option>
                      <option value="02">Fevereiro</option>
                      <option value="03">Março</option>
                      <option value="04">Abril</option>
                      <option value="05">Maio</option>
                      <option value="06">Junho</option>
                      <option value="07">Julho</option>
                      <option value="08">Agosto</option>
                      <option value="09">Setembro</option>
                      <option value="10">Outubro</option>
                      <option value="11">Novembro</option>
                      <option value="12">Dezembro</option>
                    </select>
                  </div>
                  {clientesIdsResponsaveis.length > 1 && (
                    <div className="filtro">
                      <h3>Cliente</h3>
                      <select
                        value={clienteIdSelecionado}
                        onChange={(e) => {
                          if (e.target.value === "todos") {
                            if (user && user.perfil == "administrador") {
                              return setClienteIdSelecionado(
                                clientes.map((cliente) => cliente.id)
                              );
                            }
                            console.log("aqui");
                            console.log(user.clientes);
                            return setClienteIdSelecionado(user.clientes);
                          }
                          setClienteIdSelecionado(e.target.value);
                        }}
                      >
                        <option value="todos">Todos os clientes</option>
                        {clientesIdsResponsaveis.map((cliente) => (
                          <option value={cliente.id}>
                            {cliente.nome_fantasia}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div className="space-between">
                <div className="card-Dados">
                  <div className="linha-vermelha"></div>
                  <div>
                    <h3>Quantidade de itens orçados</h3>
                    <h2>
                      {dadosDashboard.quantidadeOrcada &&
                        dadosDashboard.quantidadeOrcada.quantidade}
                    </h2>
                    <h5
                      className={`porcentagem ${dadosDashboard.porcentagens &&
                        dadosDashboard.porcentagens.quantidadeOrcada > 0
                        ? "positivo"
                        : "negativo"
                        }`}
                    >
                      {dadosDashboard.porcentagens &&
                        (dadosDashboard.porcentagens.quantidadeOrcada <= 0 ? (
                          <ReactSVG src={Negativo} />
                        ) : (
                          <ReactSVG src={Positivo} />
                        ))}
                      {dadosDashboard.porcentagens &&
                        dadosDashboard.porcentagens.quantidadeOrcada}
                      %<span> Desde o mês passado</span>
                    </h5>
                  </div>
                </div>
                <div className="card-Dados">
                  <div className="linha-vermelha"></div>
                  <div>
                    <h3>Quantidade de itens produzidos</h3>
                    <h2>
                      {dadosDashboard.quantidadeProduzida &&
                        dadosDashboard.quantidadeProduzida.quantidade}
                    </h2>
                    <h5
                      className={`porcentagem ${dadosDashboard.porcentagens &&
                        dadosDashboard.porcentagens.quantidadeProduzida > 0
                        ? "positivo"
                        : "negativo"
                        }`}
                    >
                      {dadosDashboard.porcentagens &&
                        (dadosDashboard.porcentagens.quantidadeOrcada <= 0 ? (
                          <ReactSVG src={Negativo} />
                        ) : (
                          <ReactSVG src={Positivo} />
                        ))}
                      {dadosDashboard.porcentagens &&
                        dadosDashboard.porcentagens.quantidadeProduzida}
                      %<span> Desde o mês passado</span>
                    </h5>
                  </div>
                </div>
                <div className="card-Dados">
                  <div className="linha-vermelha"></div>
                  <div>
                    <h3>Valor investido (R$)</h3>
                    <h2>
                      {dadosDashboard.valorProduzido &&
                        moneyMask(
                          Number(dadosDashboard.valorProduzido.total).toFixed(2)
                        )}
                    </h2>
                    <h5
                      className={`
                    porcentagem
                    ${dadosDashboard.porcentagens &&
                          dadosDashboard.porcentagens.valorProduzido > 0
                          ? "positivo"
                          : "negativo"
                        }`}
                    >
                      {dadosDashboard.porcentagens &&
                        (dadosDashboard.porcentagens.quantidadeOrcada <= 0 ? (
                          <ReactSVG src={Negativo} />
                        ) : (
                          <ReactSVG src={Positivo} />
                        ))}
                      {dadosDashboard.porcentagens &&
                        dadosDashboard.porcentagens.valorProduzido}
                      %<span> Desde o mês passado</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="graficos">
                {dadosDashboard.loading ? (
                  <Loading />
                ) : dadosDashboard.quantidadeOrcada.quantidade > 0 ? (
                  <div className="graficoBolinha">
                    <Doughnut
                      data={{
                        labels: dadosDashboard.quantidadeOrcada
                          ? dadosDashboard.quantidadeOrcada.array.map(
                            (element) => element.nome_marca
                          )
                          : [],
                        datasets: [
                          {
                            label: "Quantidade",
                            data: dadosDashboard.quantidadeOrcada
                              ? dadosDashboard.quantidadeOrcada.array.map(
                                (element) => element.total_itens
                              )
                              : [],
                            backgroundColor: cores,
                            borderColor: cores,
                            borderWidth: (() => {
                              const data = dadosDashboard.quantidadeOrcada
                                ? dadosDashboard.quantidadeOrcada.array.map(
                                  (element) => element.total_itens
                                )
                                : [];
                              const maxIndex = data.indexOf(Math.max(...data));
                              return data.map((_, index) =>
                                index === maxIndex ? 15 : 1
                              );
                            })(),
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                            position: "right",
                            fullSize: true,
                            align: "center",
                          },
                        },
                        cutout: "75%",
                      }}
                    />
                    <div className="grafico-vazio">
                      <ul>
                        {dadosDashboard.quantidadeOrcada &&
                          dadosDashboard.quantidadeOrcada.array.map(
                            (element, index) => (
                              <li key={index}>
                                <span
                                  style={{ background: cores[index] }}
                                ></span>
                                {element.nome_marca}
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="graficoBolinha">
                    <Doughnut
                      data={{
                        labels: ["NADA"],
                        datasets: [
                          {
                            label: "Valores",
                            data: [1, 2, 3, 4],
                            backgroundColor: [
                              "#ededed",
                              "#ededed",
                              "#ededed",
                              "#ededed",
                              "#ededed",
                            ],
                            borderColor: [
                              "#DCDCDC",
                              "#DCDCDC",
                              "#DCDCDC",
                              "#DCDCDC",
                              "#DCDCDC",
                            ],
                            borderWidth: () => {
                              const data = [1, 2, 3, 4, 5];

                              return data.map((_, index) => 3);
                            },
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                            position: "right",
                            fullSize: true,
                            align: "center",
                          },
                          tooltip: {
                            enabled: false,
                          },
                        },
                        cutout: "75%",
                      }}
                    />
                    <div className="grafico-vazio">
                      <ul>
                        <li>
                          <span></span>Não existe
                        </li>
                        <li>
                          <span></span>Não existe
                        </li>
                        <li>
                          <span></span>Não existe
                        </li>
                        <li>
                          <span></span>Não existe
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {dadosDashboard.loading ? (
                  <Loading />
                ) : dadosDashboard.quantidadeProduzida.quantidade > 0 ? (
                  <div className="graficoBolinha">
                    <Doughnut
                      data={{
                        labels: dadosDashboard.quantidadeProduzida
                          ? dadosDashboard.quantidadeProduzida.array.map(
                            (element) => element.nome_marca
                          )
                          : [],
                        datasets: [
                          {
                            label: "Quantidade",
                            data: dadosDashboard.quantidadeProduzida
                              ? dadosDashboard.quantidadeProduzida.array.map(
                                (element) => element.total_itens
                              )
                              : [],
                            backgroundColor: cores,
                            borderColor: cores,
                            borderWidth: (() => {
                              const data = dadosDashboard.quantidadeProduzida
                                ? dadosDashboard.quantidadeProduzida.array.map(
                                  (element) => element.total_itens
                                )
                                : [];
                              const maxIndex = data.indexOf(Math.max(...data));
                              return data.map((_, index) =>
                                index === maxIndex ? 15 : 1
                              );
                            })(),
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                            position: "right",
                            fullSize: true,
                            align: "center",
                          },
                        },
                        cutout: "75%",
                      }}
                    />
                    <div className="grafico-vazio">
                      <ul>
                        {dadosDashboard.quantidadeProduzida &&
                          dadosDashboard.quantidadeProduzida.array.map(
                            (element, index) => (
                              <li key={index}>
                                <span
                                  style={{ background: cores[index] }}
                                ></span>
                                {element.nome_marca}
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="graficoBolinha">
                    <Doughnut
                      data={{
                        labels: ["NADA"],
                        datasets: [
                          {
                            label: "Valores",
                            data: [1, 2, 3, 4],
                            backgroundColor: [
                              "#ededed",
                              "#ededed",
                              "#ededed",
                              "#ededed",
                              "#ededed",
                            ],
                            borderColor: [
                              "#DCDCDC",
                              "#DCDCDC",
                              "#DCDCDC",
                              "#DCDCDC",
                              "#DCDCDC",
                            ],
                            borderWidth: () => {
                              const data = [1, 2, 3, 4, 5];

                              return data.map((_, index) => 3);
                            },
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                            position: "right",
                            fullSize: true,
                            align: "center",
                          },
                          tooltip: {
                            enabled: false,
                          },
                        },
                        cutout: "75%",
                      }}
                    />
                    <div className="grafico-vazio">
                      <ul>
                        <li>
                          <span></span>Não existe
                        </li>
                        <li>
                          <span></span>Não existe
                        </li>
                        <li>
                          <span></span>Não existe
                        </li>
                        <li>
                          <span></span>Não existe
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {dadosDashboard.loading ? (
                  <Loading />
                ) : dadosDashboard.valorProduzido.total > 0 ? (
                  <div className="graficoBolinha">
                    <Doughnut
                      data={{
                        labels:
                          dadosDashboard.valorProduzido &&
                          dadosDashboard.valorProduzido.array.map(
                            (element) => element.nome_marca
                          ),
                        datasets: [
                          {
                            label: "Valores",
                            data: dadosDashboard.valorProduzido
                              ? dadosDashboard.valorProduzido.array.map(
                                (element) => element.total
                              )
                              : [],
                            backgroundColor: cores,
                            borderColor: cores,
                            borderWidth: (() => {
                              const data = dadosDashboard.valorProduzido
                                ? dadosDashboard.valorProduzido.array.map(
                                  (element) => Number(element.total)
                                )
                                : [];

                              const maxIndex = data.indexOf(Math.max(...data));
                              return data.map((_, index) =>
                                index === maxIndex ? 10 : 1
                              );
                            })(),
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                            position: "right",
                            fullSize: true,
                            align: "center",
                          },
                        },
                        cutout: "75%",
                      }}
                    />
                    <div className="grafico-vazio">
                      <ul>
                        {dadosDashboard.valorProduzido &&
                          dadosDashboard.valorProduzido.array.map(
                            (element, index) => (
                              <li key={index}>
                                <span
                                  style={{ background: cores[index] }}
                                ></span>
                                {element.nome_marca}
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="graficoBolinha">
                    <Doughnut
                      data={{
                        labels: ["NADA"],
                        datasets: [
                          {
                            label: "Valores",
                            data: [1, 2, 3, 4],
                            backgroundColor: [
                              "#F0F0F0",
                              "#F0F0F0",
                              "#F0F0F0",
                              "#F0F0F0",
                              "#F0F0F0",
                            ],
                            borderColor: [
                              "#DCDCDC",
                              "#DCDCDC",
                              "#DCDCDC",
                              "#DCDCDC",
                              "#DCDCDC",
                            ],
                            borderWidth: () => {
                              const data = [1, 2, 3, 4, 5];

                              return data.map((_, index) => 3);
                            },
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                            position: "right",
                            fullSize: true,
                            align: "center",
                          },
                          tooltip: {
                            enabled: false,
                          },
                        },
                        cutout: "75%",
                      }}
                    />
                    <div className="grafico-vazio">
                      <ul>
                        <li>
                          <span></span>Não existe
                        </li>
                        <li>
                          <span></span>Não existe
                        </li>
                        <li>
                          <span></span>Não existe
                        </li>
                        <li>
                          <span></span>Não existe
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="grafico-linha">
                <Line
                  height={"300px"}
                  data={{
                    labels: [
                      "Jan",
                      "Fev",
                      "Mar",
                      "Abr",
                      "Mai",
                      "Jun",
                      "Jul",
                      "Ago",
                      "Set",
                      "Out",
                      "Nov",
                      "Dez",
                    ],
                    datasets: [
                      {
                        label: "Total ",
                        data:
                          dadosDashboard.totalAnual &&
                          dadosDashboard.totalAnual.array,
                        borderColor: "#EF5713",
                        backgroundColor: "#EF5713",
                        borderWidth: 4,
                        pointRadius: 6,
                        pointBorderColor: "#EF5713",
                        pointBackgroundColor: "#EF5713",
                        clip: false,
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                      shadowLine: {
                        shadowColor: "rgba(1, 1, 0, 0.4)",
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowOffsetY: 4,
                      },
                      datalabels: {
                        anchor: "end",
                        align: "start",
                        formatter: (value) => value,
                        color: "black",
                        font: {
                          weight: 900,
                        },
                        offset: -18,
                      },
                    },
                    scales: {
                      x: {
                        grid: { display: false },
                        min: 0,
                      },
                      y: {
                        min: 0,
                      },
                    },
                    animations: {
                      tension: {
                        duration: 10,
                        easing: "linear",
                      },
                    },
                    animation: {
                      duration: 10,
                      delay: 10,
                    },
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <BoxRight>
          <Main>
            <div className="flex">
              <ReactSVG src={dashboard} className="menu-dash" />
              <h1>Dashboard</h1>
            </div>
            {user.permissoes.includes("cliente") ? (
              campanhas &&
                campanhas &&
                briefings &&
                tempoMedioCampanha &&
                valorTotalPropostas !== false ? (
                <Jobs>
                  <div className="graficos-campanha">
                    <div className="flex">
                      <h2 className="titulo-grafico">Campanha</h2>

                      {clientes.length > 0 && (
                        <select
                          onChange={(e) =>
                            setClienteSelecionado(e.target.value)
                          }
                          className="select-clientes"
                        >
                          <option value="">Todos os clientes</option>
                          {clientes && clientes.length > 0 ? (
                            clientes.map((element) => (
                              <option value={element.id}>
                                {element.razao_social}
                              </option>
                            ))
                          ) : (
                            <></>
                          )}
                        </select>
                      )}
                    </div>

                    <div className="quantidade-campanha">
                      <div className="top-div-grafico">
                        <div className="column">
                          <h3 className="titulo-div-grafico">
                            Total de campanhas:{" "}
                          </h3>
                          <span>Mês a Mês</span>
                        </div>
                        <select
                          onChange={(e) =>
                            listarTotalCampanhaPorAno(e.target.value)
                          }
                        >
                          {Array.from(
                            { length: new Date().getFullYear() - 2024 + 1 },
                            (_, i) => 2024 + i
                          ).map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                      <Bar
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              display: false,
                            },
                            title: {
                              display: false,
                            },
                          },
                          scales: {
                            x: { grid: { display: false } },
                            y: { grid: { display: false } },
                          },
                        }}
                        data={{
                          labels: [
                            "jan",
                            "fev",
                            "mar",
                            "abr",
                            "mai",
                            "jun",
                            "jul",
                            "ago",
                            "set",
                            "out",
                            "nov",
                            "dez",
                          ],
                          datasets: [
                            {
                              borderRadius: {
                                topLeft: 2,
                                topRight: 2,
                              },
                              label: "grafico",
                              data: quantidadeCampanhas && quantidadeCampanhas,
                              backgroundColor: "#FFBE00",
                            },
                          ],
                        }}
                      />
                      <div className="center">
                        <ReactSVG src={falante} className="falante" />
                        <span className="bold">
                          {quantidadeCampanhas &&
                            quantidadeCampanhas.reduce((a, b) => a + b, 0)}
                        </span>
                        <span className="span-little">
                          <span
                            style={{
                              fontWeight: 700,
                              marginRight: 2,
                              fontSize: 12,
                            }}
                          >
                            total
                          </span>
                          de campanhas feitas em 2024
                        </span>
                      </div>
                    </div>
                    <div className="status-itens">
                      <div className="top-div-grafico">
                        <div className="column">
                          <h3 className="titulo-div-grafico">
                            Total de jobs:{" "}
                          </h3>
                        </div>
                        <select
                          onChange={(e) =>
                            verificarBriefingsPorAno(e.target.value)
                          }
                        >
                          {Array.from(
                            { length: new Date().getFullYear() - 2024 + 1 },
                            (_, i) => 2024 + i
                          ).map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                      <Doughnut
                        data={{
                          labels: [
                            `${briefingsAnuais.filter(
                              (element) => element.status === "concluido"
                            ).length
                            } Concluídos`,
                            `${briefingsAnuais &&
                            briefingsAnuais.filter(
                              (element) =>
                                element.status === "aguardando análise" ||
                                element.status === "enviado pra orçamento" ||
                                element.status ===
                                "enviado para os fornecedores"
                            ).length
                            } aguardando retorno`,
                            `${briefingsAnuais &&
                            briefingsAnuais.filter(
                              (element) => element.status === "em produção"
                            ).length
                            } Em produção`,
                            `${briefingsAnuais &&
                            briefingsAnuais.filter(
                              (element) => Number(element.status) === 0
                            ).length
                            } Declinados`,
                          ],
                          datasets: [
                            {
                              label: "Briefings",
                              data: [
                                briefingsAnuais &&
                                briefingsAnuais.filter(
                                  (element) => element.status === "concluido"
                                ).length,
                                briefingsAnuais &&
                                briefingsAnuais.filter(
                                  (element) =>
                                    element.status === "aguardando análise" ||
                                    element.status ===
                                    "enviado pra orçamento" ||
                                    element.status ===
                                    "enviado para os fornecedores"
                                ).length,
                                briefingsAnuais &&
                                briefingsAnuais.filter(
                                  (element) =>
                                    element.status === "em produção"
                                ).length,
                                briefingsAnuais &&
                                briefingsAnuais.filter(
                                  (element) => Number(element.status) === 0
                                ).length,
                              ],
                              backgroundColor: [
                                "#000000",
                                "#FFBE00",
                                "#609ae5",
                                "#FA5252",
                              ],
                              borderColor: [
                                "#000000",
                                "#FFBE00",
                                "#609ae5",
                                "#FA5252",
                              ],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              display: true,
                              position: "bottom",
                              fullSize: true,
                              align: "start",
                            },
                          },
                        }}
                      />
                      <span className="span-center">
                        {briefingsAnuais && briefingsAnuais.length} Jobs
                      </span>
                    </div>
                    <div className="status-itens valor-campanha-grafico">
                      <div className="top-div-grafico">
                        <div className="column">
                          <h3 className="titulo-div-grafico">
                            Valor médio por Campanha:{" "}
                          </h3>
                        </div>
                      </div>
                      <ReactSVG src={imposto} />
                      <span className="valor-medio">
                        {campanhas &&
                          valorTotalPropostas !== false &&
                          valorTotalPropostas !== 0 &&
                          campanhas.length !== 0
                          ? `R$ ${(
                            valorTotalPropostas / Number(campanhas.length)
                          ).toFixed(2)}`
                          : "R$ 0,00"}
                      </span>
                    </div>
                    <div className="status-itens valor-campanha-grafico media-mes">
                      <div className="top-div-grafico">
                        <div className="column">
                          <div className="flex">
                            <div className="quantidade-campanha">
                              <div className="top-div-grafico">
                                <div className="column">
                                  <h3 className="titulo-div-grafico">
                                    Valor Médio produzido no Mês:{" "}
                                  </h3>
                                </div>
                                <select
                                  onChange={(e) =>
                                    buscarValoresProduzidos(e.target.value)
                                  }
                                >
                                  {Array.from(
                                    {
                                      length:
                                        new Date().getFullYear() - 2024 + 1,
                                    },
                                    (_, i) => 2024 + i
                                  ).map((year) => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <Bar
                                options={{
                                  responsive: true,
                                  plugins: {
                                    legend: {
                                      display: false,
                                    },
                                    title: {
                                      display: false,
                                    },
                                  },
                                  scales: {
                                    x: { grid: { display: false } },
                                    y: { grid: { display: false } },
                                  },
                                }}
                                data={{
                                  labels: [
                                    "jan",
                                    "fev",
                                    "mar",
                                    "abr",
                                    "mai",
                                    "jun",
                                    "jul",
                                    "ago",
                                    "set",
                                    "out",
                                    "nov",
                                    "dez",
                                  ],
                                  datasets: [
                                    {
                                      borderRadius: {
                                        topLeft: 2,
                                        topRight: 2,
                                      },
                                      label: "grafico",
                                      data:
                                        valorProduzidoPorMeses &&
                                        valorProduzidoPorMeses,
                                      backgroundColor: "#FFBE00",
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Jobs>
              ) : (
                <Loading />
              )
            ) : (
              <Jobs>
                <div className="graficos-campanha">
                  <div className="status-itens-fornecedor">
                    <div className="top-div-grafico">
                      <div className="column">
                        <h3 className="titulo-div-grafico">Total de jobs: </h3>
                      </div>
                      <select
                        onChange={(e) =>
                          verificarOrcamentoPorAno(e.target.value)
                        }
                      >
                        {Array.from(
                          { length: new Date().getFullYear() - 2024 + 1 },
                          (_, i) => 2024 + i
                        ).map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Doughnut
                      data={{
                        labels: [
                          `${orcamentoAnuais.filter(
                            (element) => element.status === "concluido"
                          ).length
                          } Concluídos`,
                          `${orcamentoAnuais &&
                          orcamentoAnuais.filter(
                            (element) =>
                              element.status === "pendente" ||
                              element.status === "enviado pra orçamento" ||
                              element.status === "proposta aprovada" ||
                              element.status === "aprovado"
                          ).length
                          } aguardando retorno`,
                          `${orcamentoAnuais &&
                          orcamentoAnuais.filter(
                            (element) => element.status === "em produção"
                          ).length
                          } Em produção`,
                          `${orcamentoAnuais &&
                          orcamentoAnuais.filter(
                            (element) => element.status === "Não Participa"
                          ).length
                          } Declinados`,
                          `${orcamentoAnuais &&
                          orcamentoAnuais.filter(
                            (element) => element.status === "recusado"
                          ).length
                          } Reprovado`,
                        ],
                        datasets: [
                          {
                            label: "Jobs",
                            data: [
                              orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) => element.status === "concluido"
                              ).length,
                              orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) =>
                                  element.status === "pendente" ||
                                  element.status ===
                                  "enviado pra orçamento" ||
                                  element.status === "proposta aprovada" ||
                                  element.status === "aprovado"
                              ).length,
                              orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) => element.status === "em produção"
                              ).length,
                              orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) =>
                                  element.status === "Não Participa"
                              ).length,
                              orcamentoAnuais &&
                              orcamentoAnuais.filter(
                                (element) => element.status === "recusado"
                              ).length,
                            ],
                            backgroundColor: [
                              "#000000",
                              "#FFBE00",
                              "#609ae5",
                              "#aaaaaa",
                              "#FA5252",
                            ],
                            borderColor: [
                              "#000000",
                              "#FFBE00",
                              "#609ae5",
                              "#aaaaaa",
                              "#FA5252",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: true,
                            position: "bottom",
                            fullSize: true,
                            align: "start",
                          },
                        },
                      }}
                    />
                    <span className="span-center-fornecedor">
                      {orcamentoAnuais && orcamentoAnuais.length} Jobs
                    </span>
                  </div>
                  <div className="status-itens-fornecedor">
                    <div className="top-div-grafico">
                      <div className="column">
                        <h3 className="titulo-div-grafico">Total de jobs: </h3>
                      </div>
                      <select
                        onChange={(e) =>
                          verificarOrcamentoPorAno(e.target.value)
                        }
                      >
                        {Array.from(
                          { length: new Date().getFullYear() - 2024 + 1 },
                          (_, i) => 2024 + i
                        ).map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Doughnut
                      data={{
                        labels: [
                          `${orcamentoAnuais
                            .filter((element) => element.status === "concluido")
                            .reduce(
                              (acc, element) =>
                                acc + Number(element.valor_total),
                              0
                            )} Concluídos`,
                          `${orcamentoAnuais &&
                          orcamentoAnuais
                            .filter(
                              (element) =>
                                element.status === "pendente" ||
                                element.status === "enviado pra orçamento" ||
                                element.status === "proposta aprovada" ||
                                element.status === "aprovado"
                            )
                            .reduce(
                              (acc, element) =>
                                acc + Number(element.valor_total),
                              0
                            )
                          } aguardando retorno`,
                          `${orcamentoAnuais &&
                          orcamentoAnuais
                            .filter(
                              (element) => element.status === "em produção"
                            )
                            .reduce(
                              (acc, element) =>
                                acc + Number(element.valor_total),
                              0
                            )
                          } Em produção`,
                          `${orcamentoAnuais &&
                          orcamentoAnuais
                            .filter(
                              (element) => element.status === "recusado"
                            )
                            .reduce(
                              (acc, element) =>
                                acc + Number(element.valor_total),
                              0
                            )
                          } Reprovados`,
                        ],
                        datasets: [
                          {
                            label: "Jobs",
                            data: [
                              orcamentoAnuais &&
                              orcamentoAnuais
                                .filter(
                                  (element) => element.status === "concluido"
                                )
                                .reduce(
                                  (acc, element) =>
                                    acc + Number(element.valor_total),
                                  0
                                ),
                              orcamentoAnuais &&
                              orcamentoAnuais
                                .filter(
                                  (element) =>
                                    element.status === "pendente" ||
                                    element.status ===
                                    "enviado pra orçamento" ||
                                    element.status === "proposta aprovada" ||
                                    element.status === "aprovado"
                                )
                                .reduce(
                                  (acc, element) =>
                                    acc + Number(element.valor_total),
                                  0
                                ),
                              orcamentoAnuais &&
                              orcamentoAnuais
                                .filter(
                                  (element) =>
                                    element.status === "em produção"
                                )
                                .reduce(
                                  (acc, element) =>
                                    acc + Number(element.valor_total),
                                  0
                                ),
                              orcamentoAnuais &&
                              orcamentoAnuais
                                .filter(
                                  (element) => element.status === "recusado"
                                )
                                .reduce(
                                  (acc, element) =>
                                    acc + Number(element.valor_total),
                                  0
                                ),
                            ],
                            backgroundColor: [
                              "#000000",
                              "#FFBE00",
                              "#609ae5",
                              "#FA5252",
                            ],
                            borderColor: [
                              "#000000",
                              "#FFBE00",
                              "#609ae5",
                              "#FA5252",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: true,
                            position: "bottom",
                            fullSize: true,
                            align: "start",
                          },
                        },
                      }}
                    />
                    <span className="span-valor-total">
                      Valor Total: <br />
                      {orcamentoAnuais &&
                        "R$" +
                        " " +
                        moneyMask(
                          String(
                            Number(
                              orcamentoAnuais.reduce(
                                (acc, element) =>
                                  acc + Number(element.valor_total),
                                0
                              )
                            ).toFixed(2)
                          )
                        )}{" "}
                    </span>
                  </div>
                </div>
              </Jobs>
            )}
            {user.permissoes.includes("admin") && (
              <div className="relatorio">
                <RelatorioContasPagar />
                <RelatorioContasReceber />
              </div>
            )}
            <div>
              <h2></h2>
            </div>
            <button onClick={() => setAbrirPropostasNfsPendentes(true)}>
              MIGUEL
            </button>
          </Main>
        </BoxRight>
      )}
      {abrirPropostasNfsPendentes && (
        <ModalPropostasNfsPendentes state={setAbrirPropostasNfsPendentes} />
      )}
    </DashBoardContainer>
  );
};

export default Dashboard;
