import styled from "styled-components";

export const CardVitrineStyle = styled.div`
  margin: 16px 20px 10px 10px;
  cursor: pointer;
  min-width: 210px;
  position: relative;

  &:hover > figure {
    box-shadow: 0px 3px 6px #00000029;
  }

  > figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 201px;
    height: 201px;
    overflow: hidden;
    background-color: var(--base);
    border-radius: 4px;
    border: 10px solid var(--base);

    img {
      height: 100%;
    }
  }

  .span-pendente {
    position: absolute;
    top: 80px;
    left: 20px;
    color: var(--toastify-color-error);
    font-weight: 800;
    font-size: 2rem;
  }

  h3 {
    font-size: 13px;
    font-weight: 600;
    margin: 5px 0px 3px 5px;
    width: 195px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h4 {
    display: flex;
    font-size: 12px;
    margin-left: 5px;

    b {
      font-size: 14px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
`;
