import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../providers/Auth";
import {
  Body,
  InputsTipoDeUsuario,
  InputsUsuarios,
  NovoUsuarioContainer,
} from "./style";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import Select from "react-select";
import voltar from "../../../../assets/voltar.svg";
import { ReactSVG } from "react-svg";

const EditarUsuario = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const [produtores, setProdutores] = useState(null);
  const [atendimento, setAtendimento] = useState(null);
  const [perfils, setPerfils] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [responsaveis, setResponsaveis] = useState([]);
  const [clientesResponsaveis, setClientesResponsaveis] = useState([]);
  const [produtoresSelecionados, setProdutoresSelecionados] = useState([]);
  const [atendimentoSelecionados, setAtendimentoSelecionados] = useState([]);

  const { reset, getValues } = useForm({});

  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({
    sobrenome: "",
    departamento: "",
    nome: "",
    senha: "",
    email: "",
    status: true,
    perfil_id: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUsuario({ ...usuario, [name]: value });
  };

  const onSubmitFunction = (e) => {
    e.preventDefault();
    api
      .patch(`/usuarios/${id}`, { ...usuario })
      .then((response) => {
        navigate("/usuarios");
        toast.success("Usuário atualizado com sucesso!");

        for (const cliente of clientesResponsaveis) {
          const usuario_cliente = {
            cliente_id: cliente.id,
            usuario_id: id,
            status: 1,
          };
          api
            .post("/usuario-cliente", [usuario_cliente])
            .then((response) => {})
            .catch((error) => {
              return toast.error(error.response.data.message);
            });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  function buscarClientes() {
    api
      .get("/clientes")
      .then((response) => {
        for (const cliente of response.data.data.clientes) {
          cliente.value = cliente.id;
          cliente.label = cliente.razao_social;
        }
        setClientes(response.data.data.clientes);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  //BUSCAR PERFILS
  function buscarPerfis() {
    api
      .get("/perfis")
      .then((response) => {
        setPerfils(response.data.data.perfis);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarUsuarioId() {
    api
      .get(`/usuarios/${id}`)
      .then((response) => {
        setUsuario(response.data.data.usuario);
        reset();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function buscarClientesResponsaveis() {
    api
      .get(`clientes/cliente/responsaveis/?perfil=${usuario.perfil}`, {
        params: {
          responsavel: id,
        },
      })
      .then((response) => {
        setResponsaveis(response.data.data.clientes);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function deletarResponsavel(responsavel_id) {
    api
      .delete(`clientes/cliente/responsaveis/${responsavel_id}`)
      .then((response) => {
        toast.success("Responsável deletado com sucesso!");
        buscarClientesResponsaveis();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    buscarPerfis();
    buscarClientes();
    buscarUsuarioId();
  }, []);

  useEffect(() => {
    if (usuario.perfil) {
      buscarClientesResponsaveis();
    }
  }, [usuario]);

  const clientesFiltrados =
    usuario.perfil != "cliente" &&
    clientes.filter(
      (cliente) =>
        !responsaveis.find((responsavel) => responsavel.id === cliente.id)
    );

  return (
    <NovoUsuarioContainer onSubmit={onSubmitFunction}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/usuarios")}
            className="voltar"
            src={voltar}
          />
          <div className="column">
            <h1>Usuário</h1>
            <h2>Editar usuário</h2>
          </div>
        </div>
        <Body>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              checked={usuario.status}
              onChange={(e) =>
                setUsuario({ ...usuario, status: e.target.checked })
              }
            />

            <label htmlFor="checkbox1">Ativo</label>
          </div>
          <InputsUsuarios>
            <div>
              <label>Nome </label>
              <input
                type="text"
                name="nome"
                value={usuario.nome}
                onChange={handleInputChange}
                placeholder="Nome "
              />
            </div>
          </InputsUsuarios>

          <InputsTipoDeUsuario>
            <div>
              <label>E-mail</label>
              <input
                type="email"
                name="email"
                value={usuario.email}
                onChange={handleInputChange}
                placeholder="email@email.com.br"
              />
            </div>
            <div>
              <label>Senha</label>
              <input
                type="password"
                name="senha"
                value={usuario.senha}
                onChange={handleInputChange}
                placeholder="********"
              />
            </div>
            <div>
              <label>Perfil de acesso</label>
              <select
                name="perfil_id"
                value={usuario.perfil_id}
                onChange={handleInputChange}
                disabled={true}
              >
                <option>Selecione o perfil</option>
                {perfils.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.nome}
                  </option>
                ))}
              </select>
            </div>
          </InputsTipoDeUsuario>
        </Body>
        {usuario.perfil != "cliente" && usuario.perfil != "fornecedor" ? (
          <div className="select">
            <label>Adicionar Clientes responsáveis:</label>
            <Select
              value={clientesResponsaveis}
              onChange={setClientesResponsaveis}
              options={clientes && clientesFiltrados}
              isMulti={true}
            />
          </div>
        ) : (
          <></>
        )}
        {user && user.permissoes.includes("usuario_atualizar") && (
          <div className="buttons">
            <button type="submit">Salvar</button>
          </div>
        )}
        {usuario &&
          usuario.perfil !== "cliente" &&
          usuario.perfil !== "fornecedor" && (
            <div>
              <h4 className="responsavel">Clientes responsáveis:</h4>
              <div className="flex">
                {responsaveis.map((responsavel) => (
                  <div className="item" key={responsavel.id}>
                    <span>{responsavel.nome_fantasia}</span>
                    <span
                      className="close"
                      onClick={() =>
                        deletarResponsavel(responsavel.usuario_cliente_id)
                      }
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
    </NovoUsuarioContainer>
  );
};

export default EditarUsuario;
