import styled from "styled-components";

export const ModalPropostasNfsPendentesStyle = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  .div-body {
    width: 90%;
    max-width: 1000px;
    padding: 50px;
    background-color: var(--base01);
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .aprovado {
    background-color: #79d812;
    width: max-content;
    margin: 0 auto;
    padding: 9px 8px 5px 9px;
    border-radius: 200px;

    svg {
      width: 25px;
      height: 25px;

      path {
        fill: #f9f5f5;
      }
    }
  }

  .cancelar {
    background-color: #d81212;
    width: max-content;
    margin: 0 auto;
    padding: 9px 9px 5px 10px;
    border-radius: 200px;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: #f9f5f5;
      }
    }
  }

  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    color: var(--base01);
    background-color: #fa5252;
    padding: 11px 13px;
    font-size: 30px;
    font-weight: 300;
    border-radius: 0px 0px 0px 20px;
    cursor: pointer;
    font-weight: 500;
  }

  .width-90 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
  }

  .input-text {
    width: 100%;
    padding-bottom: 50px;
    margin-top: 20px;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    max-height: 250px;
  }

  .input-check {
    width: 17px;
    height: 17px;
    margin-right: 4px;
  }

  .margin-top {
    margin-top: 30px;
  }

  button {
    position: unset;
    display: flex;
    margin: 20px auto 0px;
    padding: 8px 15px;
  }

  a {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #115ae0;
  }

  .entrega {
    width: max-content;
    margin-top: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;

    > thead > tr > th {
      font-weight: 600;
      padding: 20px;
      padding: 10px;
      font-size: 0.8rem;
    }

    > tbody > tr > td {
      border-bottom: 1px solid var(--grayDA);
      border-top: 1px solid var(--grayA5);
      padding: 15px;
      font-size: 0.8rem;
    }
  }
`;
